/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import FacilityPage from "./FacilityPage";
import CONSTANT from "../../Constant.json";
const FacilityWrapper = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | ${CONSTANT.FACILITY}`
  }, []);

  return (
    <>
      <PageTitle>{CONSTANT.FACILITIES}</PageTitle>
      <FacilityPage data={data} setData={setData} />
    </>
  );
};

export default FacilityWrapper;
