import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { postCustomer, getCustomer, editCustomer } from '../../modules/auth/redux/AuthCRUD'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import CONSTANT from '../../Constant.json'
import { useTheme } from '../../../_metronic/layout/components/ThemeProvider/ThemeProvider'
type Props = {
  show: boolean
  handleClose: () => void
  setData: any
  editData: any
  setEditData: any
}
interface ICustomer {
  name: string
}
const inits: ICustomer = {
  name: '',
}
const customerSchema = Yup.object().shape({
  name: Yup.string().required(CONSTANT.CUSTOMER_NAME_IS_REQUIRED),
})
const CustomerTypeModel: React.FC<Props> = ({
  show,
  handleClose,
  setData,
  editData,
  setEditData,
}) => {
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken)
  const [initValues, setInitialValues] = useState(inits)
  const { lightTheme, toggleTheme, themeStyles } = useTheme();

  const formikRef: any = useRef()
  const submitStep = (values: ICustomer, actions: FormikValues) => {
    if (editData && editData.id) {
      editCustomer(isAuthorized, editData.id, values.name).then((data) => {
        if (data.status === 200) {
          // toast.success(CONSTANT.CUSTOMER_EDITED_SUCCESSFULLY)
          actions.resetForm()
          handleClose()
          getCustomer(isAuthorized).then(({ data }) => setData(data.data))
        } else {
          // toast.error(CONSTANT.CUSTOMER_EDITED_FAILED)
        }
      })
        .catch(() => console.log(CONSTANT.CUSTOMER_EDITED_FAILED))
    } else {
      postCustomer(isAuthorized, values).then(({ data }) => {
        if (data.status === 'success') {
          // toast.success(CONSTANT.CUSTOMER_ADDED_SUCCESSFULLY)
          actions.resetForm()
          handleClose()
          getCustomer(isAuthorized).then(({ data }) => setData(data.data))
        } else {
          // toast.error(CONSTANT.CUSTOMER_ADDED_FAILED)
        }
      })
        .catch(() => console.log(CONSTANT.CUSTOMER_ADDED_FAILED))
    }
  }
  useEffect(() => {
    if (editData && formikRef.current && editData.name.length > 0) {
      formikRef.current.setFieldValue('name', editData.name)
    }
  }, [editData])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      onHide={handleClose}
      role='dialog'
      show={show}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      aria-hidden='true'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}
      >
        <div className='modal-header'>
          <h5 className='modal-title'>
            {editData && editData.name.length ? CONSTANT.EDIT_CUSTOMER : CONSTANT.ADD_CUSTOMER}{' '}
          </h5>

          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className='modal-body'>
          <div className='stepper-nav'>
            <Formik
              validationSchema={customerSchema}
              initialValues={initValues}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form>
                  <div className='form-group'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Name</label>
                        <div className="required"></div>
                      </div>
                    </div>
                    <div className='pt-2 pb-2'>
                      <Field
                        className='form-control form-control-lg form-control-solid'
                        name='name'
                        type='text'
                      />
                    </div>
                    {errors.name && touched.name ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span style={{ color: 'red' }} role='alert'>
                            {errors.name}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className='text-center'>
                    <div className='modal-footer'>
                      {editData ? (
                        <button type='submit' className='btn btn-primary model-submit-btn'>
                          Save
                        </button>
                      ) : (
                        <button type='submit' className='btn btn-primary model-submit-btn'>
                          Submit
                        </button>
                      )}
                      <button
                        type='button'
                        onClick={() => handleClose()}
                        className='btn  model-cancel-btn'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CustomerTypeModel
