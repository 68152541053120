import React, {
  CSSProperties,
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
// import Switch from "react-bootstrap-v5/lib/esm/Switch";
import Switch from "react-switch";
import ThemeDesign from "./ThemeDesign";
import RefreshButton from "./RefreshButton";
// Define the style interface
interface ThemeStyles {
  color: string;
  backgroundColor: string;
}
interface AsideTheme {
  backgroundColor: string;
}
interface HeaderTheme {
  backgroundColor: string;
  color: any;
}

// Create a ThemeContext
interface ThemeContextProps {
  lightTheme: boolean;
  toggleTheme: () => void;
  themeStyles: {
    light: {
      body: ThemeStyles;
      aside: AsideTheme;
      header: HeaderTheme;
      footer: ThemeStyles;

      // Add other sections with their respective styles
    };
    dark: {
      body: ThemeStyles;
      aside: AsideTheme;
      header: HeaderTheme;
      footer: ThemeStyles;

      // Add other sections with their respective styles
    };
  };
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

// Create a ThemeProvider component to wrap your entire app
const ThemeProvider: FC = ({ children }) => {
  const [lightTheme, setLightTheme] = useState<boolean>(false);

  useEffect(() => {
    // Load theme from localStorage on component mount
    if (localStorage.getItem("theme") != null) {
      const savedTheme = localStorage.getItem("theme");
      if (savedTheme) {
        setLightTheme(JSON.parse(savedTheme));
      }
    } else {
      const systemTheme = window.matchMedia(
        "(prefers-color-scheme: light)"
      ).matches;
      setLightTheme(systemTheme);
    }
  }, []);

  const toggleTheme = () => {
    setLightTheme((prevTheme) => !prevTheme);
  };

  useEffect(() => {
    // Save theme to localStorage whenever it changes
    localStorage.setItem("theme", JSON.stringify(lightTheme));

    // Get all elements with the class name .card

    ThemeDesign({ lightTheme });
  }, [lightTheme]);

  const themeStyles = {
    light: {
      body: {
        color: "#dedfe0",
        backgroundColor: "#a3acdf",
        fontColor: "#2b2b2b",
      },
      aside: {
        backgroundColor: "#dedfe0",
      },
      header: {
        color: "#2b2b2b",
        backgroundColor: "#dedfe0",
      },
      footer: {
        color: "#dedfe0",
        backgroundColor: "#a3acdf",
        fontColor: "#2b2b2b",
        // other light footer styles
      },
      // Add other sections with their respective styles
    },
    dark: {
      body: {
        color: "#dedfe0",
        backgroundColor: "#a3acdf",
        fontColor: "#2b2b2b",
      },
      aside: {
        backgroundColor: "#00105e",
      },
      header: {
        color: "#dedfe0",
        backgroundColor: "#00105e",
      },
      footer: {
        color: "#00105e",
        backgroundColor: "#203287",
        fontColor: "#dedfe0",
        // other dark footer styles
      },
      // Add other sections with their respective styles
    },
  };

  return (
    <ThemeContext.Provider value={{ lightTheme, toggleTheme, themeStyles }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Create a custom hook to consume the ThemeContext
const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

const ThemeModeSwitcher: React.FC = () => {
  const { lightTheme, toggleTheme, themeStyles } = useTheme();

  const [refreshRotate, setRefreshRotate] = useState(false);
  const headerStyle: CSSProperties = lightTheme
    ? themeStyles.light.header
    : themeStyles.dark.header;
  const fetchData = () => {
    setRefreshRotate(true);
    // Simulating data loading delay
    setTimeout(() => {
      setRefreshRotate(false);
      // setData('Data loaded!');
    }, 1500);
  };

  return (
    <>
      {/* <Switch
        onClick={toggleTheme}
        checked={!lightTheme}
        className={clsx("d-flex align-items-center cursor-pointer", "ms-1 ms-lg-3 ")}
      /> */}
      {window.location.pathname.split("/")[1] == "dashboard" && (
        <RefreshButton onRefresh={fetchData} refreshRotate={refreshRotate} />
      )}
      <Switch
        onChange={toggleTheme}
        checked={!lightTheme}
        className="mt-5"
        uncheckedIcon={
          <i
            style={{
              color: "white",
              padding: "7px 0px 0px 10px",
              height: "50px",
              width: "50px",
            }}
            className="fas fa-solid fa-sun"
          ></i>
        }
        checkedIcon={
          <i
            style={{ color: "white", padding: "7px 0px 0px 10px" }}
            className="fas fa-solid fa-moon"
          ></i>
        }
        offColor="#a3acd9"
        onColor="#8e8e8e"
      />
    </>
  );
};

export { ThemeModeSwitcher, ThemeProvider, useTheme };
