/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import { FacilityDashboardGraph } from "../../../_metronic/partials/widgets/mixed/FacilityDahboardGraph";
import { EquipmentCard } from "../../../_metronic/partials/widgets/statistics/EquipmentCard";
import { RecentAlert } from "../../../_metronic/partials/widgets/tables/Dashboard/RecentAlert";
import { RecentEvent } from "../../../_metronic/partials/widgets/tables/Dashboard/RecentEvent";
import { getEquipmentCarousel } from "../../modules/auth/redux/AuthCRUD";
import { useRefresh } from "../../../_metronic/layout/components/ThemeProvider/RefreshButton";
import { FacilityDashboardPlotlyGraph } from "../../../_metronic/partials/widgets/mixed/FacilityDashboardPlotlyGraph";
type facilityIdParams = {
  facilityId: string;
};
type Equipment = {
  current_imb: number;
  current_ll: number;
  equipment_id: string;
  equipment_name: string;
  facility_name: string;
  frequency: number;
  last_updated_epoch: number;
  power_kw: number;
  voltage_ll: number;
  voltage_ll_imb: number;
};

const FacilityDashboardPage: FC = () => {
  const history = useHistory();
  const { facilityId } = useParams<facilityIdParams>();
  const { refresh, makeRefresh } = useRefresh();

  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  // const groupSize = 3;
  const [groupSize, setGroupSize] = useState(getGroupSize());

  const handlePrev = () => {
    setActiveGroupIndex(
      activeGroupIndex === 0
        ? Math.ceil(equipments.length / groupSize) - 1
        : activeGroupIndex - 1
    );
  };

  const handleNext = () => {
    setActiveGroupIndex(
      (activeGroupIndex + 1) % Math.ceil(equipments.length / groupSize)
    );
  };
  useEffect(() => {
    if (facilityId != null) {
      getEquipmentCarousel(isAuthorized, facilityId)
        .then(({ data }) => {
          setEquipments(data.data);
        })
        .catch(() => console.log("Equipment not found"));
    }

    // setInterval(() => {
    //     console.log("facilityId", facilityId);
    //     getEquipmentCarousel(isAuthorized, facilityId)
    //         .then(({ data }) => {
    //             setEquipments(data.data);
    //         })
    //         .catch(() => console.log("Equipment not found"));
    // }, 5000)
  }, [facilityId, refresh]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getEquipmentCarousel(isAuthorized, facilityId)
        .then(({ data }) => {
          setEquipments(data.data);
        })
        .catch(() => console.log("Equipment not found"));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [facilityId, window.location]);

  // Function to calculate group size based on screen width
  function getGroupSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      return 1; // For small screens, set group size to 1
    } else if (screenWidth < 1025) {
      return 2; // For medium screens, set group size to 2
    } else if (screenWidth < 2560) {
      return 4; // For medium screens, set group size to 2
    } else {
      return 3; // For large screens and above, set group size to 3 (adjust as needed)
    }
  }

  // Update group size when window is resized
  useEffect(() => {
    function handleResize() {
      setGroupSize(getGroupSize());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);
  return (
    <>
      <div className="p-0">
        {/* begin::Row */}
        {equipments.length > 0 && (
          <div className="dashboardCarousel">
            <div className="carouselBtn  leftCarouselBtn ">
              <button
                className="carousel-control-prev position-relative "
                type="button"
                onClick={handlePrev}
              >
                <span
                  className="carousel-control-prev-icon bg-dark"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
            </div>
            <div className="carousel-container" style={{ width: "100%" }}>
              <div
                id="carouselExample"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div className="carousel-inner">
                  {[...Array(Math.ceil(equipments.length / groupSize))].map(
                    (_, groupIndex) => (
                      <div
                        key={groupIndex}
                        className={`carousel-item ${
                          groupIndex === activeGroupIndex ? "active" : ""
                        }`}
                      >
                        <div className="row">
                          {equipments
                            .slice(
                              groupIndex * groupSize,
                              groupIndex * groupSize + groupSize
                            )
                            .map((slide: Equipment) => (
                              <div
                                key={slide?.equipment_id}
                                className="col"
                                onClick={() => {
                                  history.push(
                                    `/dashboard/facility/${facilityId}/equipment/${slide?.equipment_id}`
                                  );
                                }}
                              >
                                <EquipmentCard
                                  className="card-xl-stretch mb-8"
                                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                                  color="primary"
                                  iconColor="white"
                                  details={slide}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="carouselBtn  rightBtn ">
              <button
                className="carousel-control-next bg-dark position-relative rightBtn"
                type="button"
                onClick={handleNext}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="row g-5 gx-xxl-12">
        <div className="col-xxl-6">
          <RecentAlert className="card-xxl-stretch mb-5 mb-xxl-8" />
        </div>
        <div className="col-xxl-6">
          <RecentEvent
            className="card-xxl-stretch mb-5 mb-xxl-8"
            facilityId={facilityId}
          />
        </div>
      </div>
      {/* end::Row */}
      <div className="row g-5 gx-xxl-12">
        {/* <div className="col-xxl-12">
          <FacilityDashboardGraph
            className="mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="170px"
            equipments={equipments}
            facilityId={facilityId}
            isAuthorized={isAuthorized}
          />
        </div> */}
        <div className="col-xxl-12">
          <FacilityDashboardPlotlyGraph
            className="mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="170px"
            equipments={equipments}
            facilityId={facilityId}
            isAuthorized={isAuthorized}
          />
        </div>
      </div>
    </>
  );
};
const FacilityDashboardWrapper: FC = () => {
  const intl = useIntl();
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | Dashboard`
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.FACILITY.DASHBOARD" })}
      </PageTitle>
      <FacilityDashboardPage />
    </>
  );
};

export { FacilityDashboardWrapper };
