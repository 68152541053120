/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import CustomerPage from './CustomerPage'
import CONSTANT from '../../Constant.json'

const CustomerWrapper = () => {
  useEffect(() => {
    const customer_name = localStorage.getItem('customer_name')
    // document.title = `${customer_name} | ${CONSTANT.CUSTOMERS}`
  }, []);

  return (
    <>
      <PageTitle>{CONSTANT.CUSTOMERS}</PageTitle>
      <CustomerPage />
    </>
  )
}

export default CustomerWrapper
