export const SelectStyle = {
  option: (base, { isFocused, isSelected }) => ({
    ...base,

    backgroundColor: isFocused ? "#6497ef" : base.backgroundColor,
    // width: "150px",

    // Hover color
    fontSize: "13px",
    fontWeight: 500,
    zIndex: 9999,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
    // Apply desired styles here (e.g., color, font-weight, etc.)
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: "13px",
    color: "#ffffff",
    fontWeight: 500,
  }),
  singleValue: (base) => ({
    ...base,
    // whiteSpace: "pre-wrap",
  }),
};
export const modalDropDownStyle = {
  control: (base) => ({
    ...base,
    height: "43px",
    zIndex: 0,
  }),
};
