/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useTheme } from "../../../layout/components/ThemeProvider/ThemeProvider";
import { getFacilityDashboardGraph } from "../../../../app/modules/auth/redux/AuthCRUD";
import Select from "react-select";
import { SelectStyle } from "../../../helpers/components/SelectStyle";
import { getTimezoneName } from "../../../../app/utils/GetTimeZoneName";
import { useRefresh } from "../../../layout/components/ThemeProvider/RefreshButton";
import moment from "moment";
import Plot from "react-plotly.js";

// type Equipment = {
//   current_imb: number;
//   current_ll: number;
//   equipment_id: string;
//   equipment_name: string;
//   facility_name: string;
//   frequency: number;
//   last_updated_epoch: number;
//   power_kw: number;
//   voltage_ll: number;
//   voltage_ll_imb: number;

//   // Add more properties as needed
// };

// type Props = {
//   className: string;
//   chartColor: string;
//   chartHeight: string;
//   equipments?: Equipment[];
//   facilityId: string;
//   isAuthorized: any;
// };

const FacilityDashboardPlotlyGraph = ({
  className,
  chartColor,
  chartHeight,
  equipments,
  facilityId,
  isAuthorized,
}) => {
  const chartRef = useRef(null);
  const { refresh } = useRefresh();
  const [selection, setSelection] = useState("one_day");
  const [chartData, setChartData] = useState([]);
  const [filterChartData, setFilterChartData] = useState([]);
  const bottomRef = useRef(null);
  const { lightTheme, toggleTheme, themeStyles } = useTheme();
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [selectedType, setSelectedType] = useState({
    value: JSON.stringify({ short: "current", long: "Current" }),
    label: "Current",
  });
  const meteringOptions = [
    {
      value: JSON.stringify({ short: "current", long: "Current" }),
      label: "Current",
    },
    {
      value: JSON.stringify({ short: "voltage", long: "Voltage" }),
      label: "Voltage",
    },
    {
      value: JSON.stringify({
        short: "voltage_imbalance",
        long: "Voltage Imbalance",
      }),
      label: "Voltage Imbalance",
    },
    {
      value: JSON.stringify({
        short: "current_imbalance",
        long: "Current Imbalance",
      }),
      label: "Current Imbalance",
    },
    {
      value: JSON.stringify({ short: "power_kw", long: "Power KW" }),
      label: "Power KW",
    },
    {
      value: JSON.stringify({ short: "current_thd", long: "Current THD" }),
      label: "Current THD",
    },
    {
      value: JSON.stringify({ short: "voltage_thd", long: "Voltage THD" }),
      label: "Voltage THD",
    },
  ];
  let equipmentOptions = equipments?.map((equipment) => ({
    value: equipment.equipment_id,
    label: equipment.equipment_name,
  }));
  const [graphData, setGraphData] = useState([
    {
      x: [],
      y: [],
      // type: "scatter",
      type: "scatter", // Use 'scatter' type for area chart
      mode: "lines", // Display only lines (no markers)
      fill: "tozeroy", // Fill area to zero y
      fillcolor: [lightTheme ? "#06C" : "#78a9ff"], // Color for the filled area
      line: { color: lightTheme ? "#06C" : "#78a9ff", width: 3 }, // Color for the line
      // hoverinfo: "x+y", // Display x and y values in tooltip
      // hovertemplate: " %{x} %{y}", // Custom tooltip format
      hovertemplate: `<b>Time: </b> %{x} <br><b>${selectedType?.label}: </b> %{y}<extra></extra>`,
    },
  ]);

  const [layout, setLayout] = useState({
    // title: "Current, Voltage vs Frequency",
    // title: " Current vs. Epoch",
    xaxis: {
      title: "",
      showgrid: false,
      showline: true,
      showticklabels: false,
      type: "date", // Treat x values as dates
      // tickformat: "&nbsp; %H:%M:%S\n%Y-%m-%d",
      tickformat: "%Y-%m-%d %H:%M:%S",
      titlefont: { color: lightTheme ? "black" : "white" },
      tickfont: { color: lightTheme ? "black" : "white" }, // Format the x-axis tick labels as desired
      color: lightTheme ? "black" : "white",
      // tickpad: 100,
      // range: [
      //   -new Date(),
      //   graphData[0].x.length > 0 ? Math.max(...graphData[0].y) : new Date(),
      // ], // Adjust y-axis range as needed
    },
    yaxis: {
      showline: true,
      showgrid: false,
      title: "",
      titlefont: { color: lightTheme ? "black" : "white" },
      tickfont: { color: lightTheme ? "black" : "white" },
      range: [0, graphData[0].y.length > 0 ? Math.max(...graphData[0].y) : 10], // Adjust y-axis range as needed
    },
    legend: {
      bgcolor: "transparent",
      font: { color: lightTheme ? "black" : "white", size: 16 },
    },
    margin: {
      t: 40,
      r: 80,
      b: 80,
      l: 80,
    },
  });
  function utcEpochToLocalEpoch(utcEpochSeconds) {
    // Convert UTC epoch seconds to milliseconds
    const utcMilliseconds = utcEpochSeconds * 1000;

    // Get the current timezone offset in milliseconds
    const timezoneOffsetMilliseconds =
      new Date().getTimezoneOffset() * 60 * 1000;

    // Adjust the time based on the timezone offset
    const localMilliseconds = utcMilliseconds - timezoneOffsetMilliseconds;
    console.log("offset", Math.floor(localMilliseconds / 1000));
    // Convert milliseconds back to seconds and return
    return Math.floor(localMilliseconds);
  }
  useEffect(() => {
    if (selectedEquipment) {
      getFacilityDashboardGraph(isAuthorized, facilityId, selectedEquipment)
        .then(({ data }) => {
          setChartData(data.data);

          const extractValues = (array, keys) => {
            return array.map((item) =>
              keys.map((key) =>
                key == "epoch"
                  ? // new Date(utcEpochToLocalEpoch(item[key])).toLocaleString()
                    item[key] * 1000
                  : Number(item[key]).toFixed(2)
              )
            );
          };
          let allData = [...graphData];
          allData[0].x = extractValues(data.data, ["epoch"]).flat();
          allData[0].y = extractValues(data.data, [selectedType.short]).flat();

          setGraphData(allData);

          let newLayout = { ...layout };
          newLayout.yaxis.range = [
            0,
            Math.max(...extractValues(data.data, [selectedType.short]).flat()),
          ];
          newLayout.xaxis.title = "Time";
          newLayout.xaxis.showticklabels = true;

          newLayout.yaxis.title = selectedType?.long;
          // newLayout.xaxis.range = [
          //   Math.min(allData[0].x) + 1,
          //   Math.max(allData[0].x) - 1,
          // ];

          // Keys to extract
          const keysToExtract = ["epoch", selectedType.short];

          // Extracted key-value pairs
          const extractedKeyValuePairs = extractValues(
            data.data,
            keysToExtract
          );
          console.log("extractedKeyValuePairs", extractedKeyValuePairs);
          setFilterChartData(extractedKeyValuePairs);
        })
        .catch(() => console.log("Equipment not found"));
    }
  }, [selectedEquipment, refresh]);
  console.log("graphdata", graphData);
  useEffect(() => {
    setSelectedEquipment("");
    setChartData([]);
    setFilterChartData([]);
    setSelectedType({ short: "current", long: "Current" });
  }, [facilityId]);

  useEffect(() => {
    const extractValues = (array, keys) => {
      return array.map((item) =>
        keys.map((key) =>
          key == "epoch" ? item[key] * 1000 : Number(item[key]).toFixed(2)
        )
      );
    };

    // Keys to extract
    const keysToExtract = ["epoch", selectedType.short];
    let allData = [...graphData];
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezoneName = getTimezoneName(timezoneOffset);
    allData[0].x = extractValues(chartData, ["epoch"]).flat();
    allData[0].y = extractValues(chartData, [selectedType.short]).flat();
    let unit = "%";
    if (selectedType?.short == "current") unit = "A";
    if (selectedType?.short == "voltage") unit = "V";
    if (selectedType?.short == "power_kw") {
      unit = "kW";
    }
    allData[0].hovertemplate = `<b>Time: </b> %{x} ${timezoneName} <br><b>${selectedType?.long}: </b> %{y} ${unit}<extra></extra>`;

    setGraphData(allData);

    let newLayout = { ...layout };
    console.log("newLayoutnewLayout", newLayout);
    if (selectedEquipment) {
      if (typeof newLayout.yaxis.title == "object") {
        newLayout.yaxis.title.text = selectedType?.long;
      } else {
        newLayout.yaxis.title = selectedType?.long;
      }
    }
    // newLayout.title = `${selectedType?.long} vs Epoch`;
    if (allData[0].y.length > 0)
      newLayout.yaxis.range = [0, Math.max(...allData[0].y)];
    setLayout(newLayout);

    // Extracted key-value pairs
    if (selectedEquipment) {
      const extractedKeyValuePairs = extractValues(chartData, keysToExtract);

      setFilterChartData(extractedKeyValuePairs);
    }
  }, [selectedType]);
  useEffect(() => {
    let newLayout = { ...layout };
    console.log("newLayout", newLayout);
    let color = lightTheme ? "black" : "white";
    newLayout.xaxis.color = color;
    newLayout.yaxis.color = color;

    newLayout.xaxis.tickfont.color = color;

    newLayout.yaxis.tickfont.color = color;
    newLayout.paper_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    newLayout.plot_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    setLayout(newLayout);
    let newGraphData = { ...graphData[0] };
    newGraphData.fillcolor = [lightTheme ? "#06C" : "#78a9ff"];
    newGraphData.line.color = lightTheme ? "#06C" : "#78a9ff";

    setGraphData([newGraphData]);
  }, [lightTheme]);

  const updateData = (timeline) => {
    setSelection(timeline);

    switch (timeline) {
      case "one_day":
        ApexCharts.exec(
          "area-datetime",
          "zoomX"
          // new Date().getTime(),
          // new Date().getTime()
        );
        break;
      case "one_month":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("28 Jan 2013").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "six_months":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("27 Sep 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "one_year":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("27 Feb 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "ytd":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("01 Jan 2013").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "all":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("23 Jan 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      default:
    }
  };

  return (
    <div className={`card ${className} `}>
      {/* begin::Body */}
      <div className="card-body d-flex flex-column p-0">
        {/* begin::Stats */}
        <div className="flex-grow-1 card-p pb-0">
          <div className="d-flex flex-stack flex-wrap">
            <div className="me-2 ">
              <a
                href="#"
                className="text-dark text-hover-primary fw-bolder fs-1"
              >
                Trending Analysis
              </a>

              {/* <div className='text-muted fs-7 fw-bold'>Power KW</div> */}
            </div>

            <div className={`fw-bolder fs-3 text-${chartColor} d-flex`}>
              <div style={{ display: "inline-flex", height: "fit-content" }}>
                <button
                  className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="one_day"
                  onClick={() => updateData("one_day")}
                >
                  1D
                </button>
                &nbsp;
                <button
                  className="btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="one_month"
                  onClick={() => updateData("one_month")}
                  disabled
                >
                  1M
                </button>
                &nbsp;
                <button
                  className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="six_months"
                  onClick={() => updateData("six_months")}
                  disabled
                >
                  6M
                </button>
                &nbsp;
                <button
                  className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="one_year"
                  onClick={() => updateData("one_year")}
                  disabled
                >
                  1Y
                </button>
                &nbsp;
                <button
                  className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="ytd"
                  onClick={() => updateData("ytd")}
                  disabled
                >
                  YTD
                </button>
                &nbsp;
                <button
                  className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                  id="all"
                  onClick={() => updateData("all")}
                  disabled
                >
                  ALL
                </button>
              </div>

              {/* <select className='w-100px fs-5 ms-2' value={selectedEquipment} onChange={(e) => setSelectedEquipment(e.target.value)} >
                                <option value={""} selected disabled>Equipment</option>

                                {equipments?.map(equipment => (
                                    <option key={equipment.equipment_id} value={equipment.equipment_id} >{equipment.equipment_name}</option>

                                ))}
                            </select>
                            <select className='w-100px fs-5 ms-2' onChange={e => setSelectedType(JSON.parse(e.target.value))} >
                                <option value={JSON.stringify({ short: "current", long: "Current" })} selected>Current</option>
                                <option value={JSON.stringify({ short: "voltage", long: "Voltage" })} >Voltage</option>
                                <option value={JSON.stringify({ short: "voltage_imbalance", long: "Voltage Imbalance" })} >Voltage Imbalance</option>
                                <option value={JSON.stringify({ short: "current_imbalance", long: "Current Imbalance" })} >Current Imbalance</option>
                                <option value={JSON.stringify({ short: "power_kw", long: "Power KW" })}>Power KW</option>
                                <option value={JSON.stringify({ short: "current_thd", long: "Current THD" })}>Current THD</option>
                                <option value={JSON.stringify({ short: "voltage_thd", long: "Voltage THD" })}>Voltage THD</option>
                            </select> */}
            </div>
          </div>
          <div className="d-flex  mt-2">
            <Select
              placeholder="Equipment"
              styles={SelectStyle}
              className="fs-8 ms-2 react-select-dropdown"
              defaultValue={selectedEquipment}
              onChange={(e) => setSelectedEquipment(e.value)}
              options={equipmentOptions}
            />
            <Select
              styles={SelectStyle}
              className="fs-8 ms-2 react-select-dropdown trending-analysis"
              defaultValue={selectedType}
              onChange={(value) => setSelectedType(JSON.parse(value.value))}
              options={meteringOptions}
            />
          </div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}

        <Plot
          config={{ displaylogo: false, responsive: true }}
          data={graphData}
          layout={layout}
          style={{ width: "100%", height: 550 }} // Adjust width and height as needed
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { FacilityDashboardPlotlyGraph };
