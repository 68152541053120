/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import RolePage from "./RolePage";
import CONSTANT from "../../Constant.json";

const RoleWrapper = () => {
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | ${CONSTANT.ROLES}`
  }, []);

  return (
    <>
      <PageTitle>{CONSTANT.ROLE}</PageTitle>
      <RolePage />
    </>
  );
};

export default RoleWrapper;
