/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CONSTANT from "../../../../app/Constant.json";
import { getFacility } from "../../../../app/modules/auth/redux/AuthCRUD";
import { AsideMenuItem } from "./AsideMenuItem";
import Select from "react-select";
import { SelectStyle } from "../../../helpers/components/SelectStyle";
import { useRefresh } from "../ThemeProvider/RefreshButton";

export function AsideMenuMain() {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const [selectFacility, setSelectedFacility] = useState<any>();
  const { makeRefresh } = useRefresh();
  const role = useSelector<any>(({ auth }) => auth.role) as {
    cusutomer: { view: boolean };
    facility: { view: boolean };
    userRole: { view: boolean };
    user: { view: boolean };
    device: { view: boolean };
    EquipmentType: { view: boolean };
    Equipment: { view: boolean };
  };
  useEffect(() => {
    getFacility(isAuthorized)
      .then(({ data }) => {
        let options = data.data.map((d: any) => ({
          value: d.id,
          label: d.name,
        }));
        setData(options);
        // setData(data.data)
        let localStorageValue = localStorage.getItem("selectedfacility");
        if (localStorageValue) {
          const findObject = options.filter(
            (d: any) => d.value == localStorageValue
          );
          setSelectedFacility(findObject[0]);
        } else {
          setSelectedFacility(options[0]?.value);
        }
      })
      .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
  }, []);

  const handleChangefacility = (value: any) => {
    const findObject = data.filter((d: any) => d.value == value);

    setSelectedFacility(findObject[0]);
    localStorage.setItem("selectedfacility", value);
    if (window.location.pathname.split("/")?.[1] == "equipments") {
      return makeRefresh();
    }
    history.push(`/dashboard/facility/${value}`);
  };

  // console.log("selectedfacility", selectFacility, data);
  const intl = useIntl();
  // Side menubar for theme

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-uppercase fs-8 ls-1 menu-theme">
            {intl.formatMessage({ id: "MENU.FACILITY" })}
          </span>
        </div>
      </div>

      <div className="sidebar-dropDownDiv">
        {/* <select onChange={(e) => { setSelectedFacility(e.target.value) }}
          value={selectFacility}
          onClick={(e) => { history.push(`/dashboard/facility/${selectFacility}`); localStorage.setItem("selectedfacility", selectFacility) }}
          className='sideBar-dropDown'
        >
          {
            data && Array.isArray(data) && data.map((item, index) => (
              <>
                <option key={index} value={item?.id}> {item.name}</option>
              </>
            ))
          }
        </select> */}
        <Select
          styles={SelectStyle}
          className="fs-8 ms-2 react-select-dropdown"
          value={selectFacility}
          onChange={(e) => {
            handleChangefacility(e.value);
          }}
          options={data}
        />
      </div>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-uppercase fs-8 ls-1 menu-theme">
            {intl.formatMessage({ id: "MENU.MONITORING" })}
          </span>
        </div>
      </div>
      <span
        onClick={() => {
          setSelectedFacility(data?.[0]);
          localStorage.setItem("selectedfacility", data?.[0]?.value);
        }}
      >
        <AsideMenuItem
          to={`/dashboard/facility/${data?.[0]?.value}`}
          icon="/media/icons/duotune/layouts/lay001.svg"
          title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
          fontIcon="bi-app-indicator"
        />
      </span>
      {/* <AsideMenuItem
        to="/energy"
        icon="/media/icons/duotune/layouts/Energy.svg"
        title={intl.formatMessage({ id: "MENU.ENERGY" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/health"
        icon="/media/icons/duotune/layouts/Health.svg"
        title={intl.formatMessage({ id: "MENU.HEALTH" })}
        fontIcon="bi-app-indicator"
      /> */}

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section  text-uppercase fs-8 ls-1 menu-theme">
            {intl.formatMessage({ id: "MENU.ADMINSTRATION" })}
          </span>
        </div>
      </div>

      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Manage'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      > */}
      {role.cusutomer.view && (
        <AsideMenuItem
          to="/customer"
          icon="/media/icons/duotune/communication/com006.svg"
          title={intl.formatMessage({ id: "MENU.CUSTOMER" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.facility.view && (
        <AsideMenuItem
          to="/facility"
          icon="/media/icons/duotune/art/art002.svg"
          title={intl.formatMessage({ id: "MENU.FACILITIES" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.userRole.view && (
        <AsideMenuItem
          to="/roles"
          icon="/media/icons/duotune/art/art008.svg"
          title={"Roles"}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.user.view && (
        <AsideMenuItem
          to="/users"
          icon="/media/icons/duotune/communication/com005.svg"
          title={intl.formatMessage({ id: "MENU.USERS" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.device.view && (
        <AsideMenuItem
          to="/device"
          icon="/media/icons/duotune/technology/teh001.svg"
          title={intl.formatMessage({ id: "MENU.DEVICE" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.EquipmentType.view && (
        <AsideMenuItem
          to="/equipment-type"
          icon="/media/icons/duotune/technology/teh008.svg"
          title={intl.formatMessage({ id: "MENU.EQUIPMENT_TYPE" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {role.Equipment.view && (
        <AsideMenuItem
          to="/equipments"
          icon="/media/icons/duotune/art/art002.svg"
          title={intl.formatMessage({ id: "MENU.EQUIPMENT" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {/* </AsideMenuItemWithSub> */}

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section  text-uppercase fs-8 ls-1 menu-theme  ">
            {intl.formatMessage({ id: "MENU.PERSONAL" })}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="/notifications"
        icon="/media/icons/duotune/layouts/Notification.svg"
        title={intl.formatMessage({ id: "MENU.NOTIFICATIONS" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile"
        icon="/media/icons/duotune/layouts/Profile.svg"
        title={intl.formatMessage({ id: "MENU.PROFILE" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/logout"
        icon="/media/icons/duotune/layouts/Logout.svg"
        title={intl.formatMessage({ id: "MENU.LOGOUT" })}
        fontIcon="bi-app-indicator"
      />
    </>
  );
}
