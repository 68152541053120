/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { CurrentDateToGivenTimeCalculation } from "../../../helpers/components/TimeDifferenceCalculate";
import { useTheme } from "../../../layout/components/ThemeProvider/ThemeProvider";

type Equipment = {
  current_imb: number;
  current_ll: number;
  equipment_id: string;
  equipment_name: string;
  facility_name: string;
  frequency: number;
  last_updated_epoch: number;
  power_kw: number;
  voltage_ll: number;
  voltage_ll_imb: number;
};

type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;

  details: Equipment;
};

const EquipmentCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,

  details,
}) => {
  const { lightTheme } = useTheme();

  return (
    <div
      className={`card bg-${color} hoverable ${className} ${
        lightTheme ? "lightCard" : "darkCard"
      }`}
    >
      {/* begin::Body */}
      <div className="card-body  ">
        <div className="w-100 mb-3 d-flex justify-content-between">
          <div className="">
            <div
              className={`text-inverse-${color} fw-bolder fs-1 mb-2 cardData`}
            >
              {details?.equipment_name}
            </div>

            <div className={`fw-bold text-inverse-${color} fs-4 cardData`}>
              {details?.facility_name}
            </div>
          </div>
          {details?.last_updated_epoch != 0 && (
            <div className="text-end">
              <div>
                <div className={`fw-bold text-inverse-${color} fs-4 cardData`}>
                  Last updated
                </div>
                <div
                  className={`fw-bold text-inverse-${color} fs-4 cardData`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={moment(details?.last_updated_epoch * 1000).format(
                    "YYYY-MM-DD hh:mm:ss"
                  )}
                >
                  {CurrentDateToGivenTimeCalculation(
                    details?.last_updated_epoch * 1000
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Divider className="equipmentCard-divider" />
        {details?.last_updated_epoch != 0 ? (
          <div className="w-100 ">
            <div
              className={`fw-bold text-inverse-${color} fs-4 d-flex cardData justify-content-between `}
            >
              <div>Voltage l-l</div>
              {details?.voltage_ll} V
            </div>
            <div
              className={`fw-bold text-inverse-${color} fs-4 d-flex cardData justify-content-between `}
            >
              <div>Current</div>
              <div>{details?.current_ll} A</div>
            </div>
            <div
              className={`fw-bold text-inverse-${color} fs-4 d-flex cardData justify-content-between `}
            >
              <div>Frequency</div>
              {details?.frequency} Hz
            </div>

            <div
              className={`fw-bold text-inverse-${color} fs-4 d-flex cardData justify-content-between `}
            >
              <div>Power</div>
              {details?.power_kw} KW
            </div>
            <div
              className={`fw-bold text-inverse-${color} fs-4 d-flex cardData justify-content-between `}
            >
              <div>Current imb</div>
              {details?.current_imb} %
            </div>
          </div>
        ) : (
          <div className="text-muted no-content py-3">No Data Available</div>
        )}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { EquipmentCard };
