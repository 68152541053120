import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import Select from "react-select";
import {
  getEsaFilesList,
  getEsaGraphData,
} from "../../../../app/modules/auth/redux/AuthCRUD";
import {
  getTimezoneAbbreviation,
  getTimezoneName,
} from "../../../../app/utils/GetTimeZoneName";
import { SelectStyle } from "../../../helpers/components/SelectStyle";
import { CurrentDateToGivenTimeCalculation } from "../../../helpers/components/TimeDifferenceCalculate";
import { useRefresh } from "../../../layout/components/ThemeProvider/RefreshButton";
import { useTheme } from "../../../layout/components/ThemeProvider/ThemeProvider";

const EsaWaveformGraph = ({
  className,
  facilityId,
  equipmentId,
  isAuthorized,
  equipment,
  facility,
}) => {
  const [esaFileList, setEsaFileList] = useState([]);
  const { lightTheme } = useTheme();
  const { refresh } = useRefresh();
  const [selectedESA, setSelectedESA] = useState("");
  const [chartData, setChartData] = useState();
  const [showLoading, setShowLoading] = useState(false);
  function maxFrequency(frequency, chunkSize = 10000) {
    let maxValue = 0;
    for (let i = 0; i < frequency.length; i += chunkSize) {
      const chunk = frequency.slice(
        i,
        Math.min(i + chunkSize, frequency.length)
      );
      const chunkMax = Math.max(...chunk.map(Math.abs));
      maxValue = Math.max(maxValue, chunkMax);
    }
    return maxValue;
  }

  const [graphData, setGraphData] = useState([
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "V12",
      line: { color: "red", width: 2 }, // Color for the line

      hovertemplate: `<b>Time:</b> %{x}<br><b>Voltage V12:</b> %{y} V<extra></extra>`,
      // yaxis: "y1",
    },
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "V23",
      // yaxis: "y2",
      line: { color: "yellow", width: 2 }, // Color for the line

      hovertemplate: `<b>Time:</b> %{x}<br><b>Voltage V23:</b> %{y} V <extra></extra>`,
    },
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "V31",
      // yaxis: "y2",
      line: { color: "#4285F4", width: 2 }, // Color for the line

      hovertemplate: `<b>Time:</b> %{x}<br><b>Voltage V31:</b> %{y} V<extra></extra>`,
    },
  ]);

  const [currentGraphData, setCurrentGraphData] = useState([
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "I1",
      line: { color: "red", width: 2 }, // Color for the line
      xaxis: "x",
      yaxis: "y2",
      // yaxis: "y1",
      hovertemplate: `<b>Time:</b> %{x}<br><b>Current I1:</b> %{y} A<extra></extra>`,
    },
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "I2",
      xaxis: "x",
      yaxis: "y2",
      line: { color: "yellow", width: 2 }, // Color for the line
      hovertemplate: `<b>Time:</b> %{x}<br><b>Current I2:</b> %{y} A<extra></extra>`,
    },
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "I3",
      xaxis: "x",
      yaxis: "y2",
      line: { color: "#4285F4", width: 2 }, // Color for the line
      hovertemplate: `<b>Time:</b> %{x}<br><b>Current I3:</b> %{y} A<extra></extra>`,
    },
  ]);

  const [layout, setLayout] = useState({
    title: "Voltage (V)",
    annotations: [
      {
        xref: "paper",
        yref: "paper",
        text: "Current (A)",
        showarrow: false,
        font: {
          size: 16,

          color: "black",
        },
      },
    ],

    titlefont: { color: lightTheme ? "black" : "white" },
    // title: "Current, Voltage vs Frequency",
    height: 670,
    margin: {
      t: 80, // Top margin (reduce space)
      r: 80, // Right margin (reduce space)
      b: 80, // Bottom margin (optional adjustment)
      l: 80, // Left margin (optional adjustment)
    },

    grid: {
      rows: 2,
      columns: 1,
      pattern: "independent",
      subplots: [["xy"], ["xy2"]],
      xside: "bottom",
      // roworder: "top to bottom",
    },
    xaxis: {
      title: "Time (sec)",
      range: [-0.5, 10],

      //   autorange: false,
      showline: false,
      showgrid: false,
      // showticklabels: true,
      // domain: [0.05],
      //   visible: false,
      color: lightTheme ? "black" : "white",
    },

    yaxis: {
      title: "Voltage (V)",
      //   range: [0, 100],
      zeroline: true,

      showgrid: true,
      titlefont: { color: lightTheme ? "black" : "white" },
      tickfont: { color: lightTheme ? "black" : "white" },
      gridcolor: lightTheme ? "black" : "white",
      zerolinecolor: lightTheme ? "black" : "white",
    },
    xaxis2: {
      // title: "Time",
      range: [-0.5, 10],
      rangemode: "tozero",
      //   autorange: false,
      showline: false,
      showgrid: false,
      // showticklabels: true,
      // domain: [0.05],
      //   visible: false,
      color: lightTheme ? "black" : "white",
    },
    yaxis2: {
      title: "Current (A)",
      //   range: [0, 100],
      zeroline: true,

      showgrid: true,
      titlefont: { color: lightTheme ? "black" : "white" },
      tickfont: { color: lightTheme ? "black" : "white" },
      gridcolor: lightTheme ? "black" : "white",
      zerolinecolor: lightTheme ? "black" : "white",
    },
    legend: {
      bgcolor: "transparent",
      font: { color: lightTheme ? "black" : "white", size: 16 },
    },
  });

  useEffect(() => {
    getEsaFilesList(isAuthorized, facilityId, equipmentId)
      .then(({ data }) => {
        // return `${formattedTime}  ${getTimezoneName(timezoneOffset)}`;
        // let options = data.data.map((d) => ({

        //   label:
        //     formattedTime +
        //     getTimezoneName(timezoneOffset) +
        //     CurrentDateToGivenTimeCalculation(d.epoch * 1000),
        //   value: d.s3_uri,
        // }));
        let options = data.data.map((d) => {
          const date = new Date(d.epoch * 1000); // Convert seconds to milliseconds
          const localTime = date.toLocaleTimeString([], { hour12: false });
          const year = date.getFullYear().toString().padStart(4, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-indexed
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours(); // Get hours in 12-hour format
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const ampm = date.getHours() >= 12 ? "PM" : "AM"; // Determine AM/PM
          const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          const timezoneOffset = new Date().getTimezoneOffset();
          return {
            label:
              formattedTime +
              " " +
              // getTimezoneName(timezoneOffset) +
              getTimezoneAbbreviation(timezoneOffset) +
              " (" +
              CurrentDateToGivenTimeCalculation(d.epoch * 1000) +
              ")",
            value: d.s3_uri,
          };
        });

        setEsaFileList(options);
      })
      .catch(() => console.log("FFT Files not found"));
    if (selectedESA) {
      fetchGraphData();
    }
  }, [refresh]);
  const fetchGraphData = () => {
    setShowLoading(true);
    getEsaGraphData(isAuthorized, facilityId, equipmentId, selectedESA)
      .then(({ data }) => {
        console.log("data", data);
        let allData = [...graphData];
        allData[0].x = data.data.time_sec;
        allData[1].x = data.data.time_sec;
        allData[2].x = data.data.time_sec;

        allData[0].y = data.data.v12;
        allData[1].y = data.data.v23;
        allData[2].y = data.data.v31;

        setGraphData(allData);
        let allCurrentData = [...currentGraphData];
        allCurrentData[0].x = data.data.time_sec;
        allCurrentData[1].x = data.data.time_sec;
        allCurrentData[2].x = data.data.time_sec;

        allCurrentData[0].y = data.data.i1;
        allCurrentData[1].y = data.data.i2;
        allCurrentData[2].y = data.data.i3;

        setCurrentGraphData(allCurrentData);
        let newLayout = { ...layout };
        newLayout.xaxis.zeroline = false;
        newLayout.xaxis.range = [
          Math.min(...data.data.time_sec) - 0.01,
          Math.max(...data.data.time_sec),
        ];
        newLayout.xaxis2.range = [
          Math.min(...data.data.time_sec) - 0.01,
          Math.max(...data.data.time_sec),
        ];

        // setData(allData);
        setChartData(data.data);
        setShowLoading(false);
      })
      .catch((e) => {
        console.log("ESA Files not found", e);

        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (Array.isArray(chartData?.freq_hz)) {
      let newLayout = { ...layout };
      newLayout.xaxis.range = [0, maxFrequency(chartData?.freq_hz)];
      setLayout(newLayout);
    }
  }, [chartData]);
  useEffect(() => {
    let newLayout = { ...layout };
    // console.log("newLayout", newLayout);
    let color = lightTheme ? "black" : "white";
    let gridColor = lightTheme
      ? "rgba(0, 0, 0, 0.27)"
      : "rgba(255, 255, 255, 0.27)";
    newLayout.xaxis.color = color;
    newLayout.xaxis2.color = color;

    newLayout.yaxis.tickfont.color = color;
    newLayout.yaxis2.tickfont.color = color;

    console.log(typeof newLayout.yaxis.title);
    if (typeof newLayout.yaxis.title == "object") {
      newLayout.yaxis.title.font.color = color;
      newLayout.yaxis2.title.font.color = color;
      //   newLayout.xaxis2.title.font.color = color;
    }

    newLayout.legend.font.color = color;
    if (typeof newLayout.titlefont == "object")
      newLayout.titlefont.color = color;
    else {
      newLayout.title.font.color = color;
    }
    newLayout.yaxis.zerolinecolor = gridColor;
    newLayout.yaxis.gridcolor = gridColor;
    newLayout.yaxis2.zerolinecolor = gridColor;
    newLayout.yaxis2.gridcolor = gridColor;
    newLayout.annotations[0].font.color = color;
    newLayout.paper_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    newLayout.plot_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    setLayout(newLayout);
  }, [lightTheme]);

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className="flex-grow-1 card-p pb-0">
          <div className=" flex-stack flex-wrap">
            <div className="me-2">
              <div className="text-dark text-hover-primary fw-bolder fs-1">
                ESA Waveform of {equipment} from {facility}
              </div>

              {/* <div className='text-muted fs-7 fw-bold'>Power KW</div> */}
            </div>

            <div className={`fw-bolder fs-3 my-4`}>
              <div className="d-inline-flex align-items-center ">
                <div className="text-muted fw-bold">Select capture</div>
                &nbsp;
                <Select
                  placeholder="ESA files"
                  styles={SelectStyle}
                  className="fs-8 ms-2 react-select-dropdown fft-files"
                  defaultValue={selectedESA}
                  onChange={(e) => setSelectedESA(e?.value)}
                  options={esaFileList}
                />
                &nbsp;
                {selectedESA && (
                  <>
                    <Button
                      variant="contained"
                      className="plotBtn"
                      onClick={fetchGraphData}
                      disabled={showLoading}
                    >
                      Plot
                      {showLoading && (
                        <div
                          className="spinner-border ms-2"
                          role="status"
                        ></div>
                      )}
                    </Button>
                  </>
                  // <div className="card-toolbar">
                  //   <a
                  //     // ref={myRefname}
                  //     onClick={fetchGraphData}
                  //     className="btn btn-sm btn-light-primary"
                  //   >
                  //     Plot
                  //   </a>
                  // </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body  flex-column p-0" style={{ display: "flex" }}>
          {/* begin::Stats */}
          {/* end::Stats */}

          {/* begin::Chart */}
          {/* {esaFileList.length == 0 && (
            <p className="fft-not-found">No ESA Files Found</p>
          )} */}
          <Plot
            config={{
              displaylogo: false,
              responsive: true,
            }}
            data={[...graphData, ...currentGraphData]}
            layout={{ ...layout }}
            style={{ width: "100%", height: 670 }}
            // subplots={[["xy", "xy2"]]}
            // Adjust width and height as needed
          />

          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export default EsaWaveformGraph;
