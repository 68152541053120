/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFacilityRecentEvents } from "../../../../../app/modules/auth/redux/AuthCRUD";
import { CurrentDateToGivenTimeCalculation } from "../../../../helpers/components/TimeDifferenceCalculate";
import { useRefresh } from "../../../../layout/components/ThemeProvider/RefreshButton";

type Props = {
    className: string;
    facilityId: string;
};
interface EVENT {
    epoch: number;
    equipment_id: string;
    equipment_name: string;
    event_name: string;
    facility_name: string;
    time: string;
}

const RecentEvent: React.FC<Props> = ({ className, facilityId }) => {
    const isAuthorized = useSelector(({ auth }: any) => auth.accessToken);
    const { refresh, makeRefresh } = useRefresh();
    const [events, setEvents] = useState([]);
    useEffect(() => {
        if (facilityId) {
            getFacilityRecentEvents(isAuthorized, facilityId)
                .then(({ data }) => {
                    setEvents(data.data);
                })
                .catch(() => console.log("Events not found"));
        }
    }, [facilityId, refresh]);

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-1 mb-1">Recent Events</span>
                    {/* <span className='text-muted mt-1 fw-bold fs-7'>More than 10 new Events</span> */}
                </h3>
                <div className="card-toolbar"></div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body py-3">
                <div className="tab-content">
                    {/* begin::Tap pane */}
                    <div
                        className="tab-pane fade show active"
                        id="kt_table_widget_5_tab_1"
                    >
                        {/* begin::Table container */}
                        <div className="table-responsive">
                            {/* begin::Table */}
                            <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                {/* begin::Table head */}
                                <thead>
                                    <tr className="border-0 fs-5 ">
                                        <th className="p-0  text-muted fw-bolder">Sr No.</th>

                                        <th className="p-0 text-muted fw-bolder">Equipment</th>
                                        <th className="p-0 text-muted fw-bolder">Facility</th>
                                        <th className="p-0  text-muted fw-bolder">Event</th>
                                        <th className="p-0 text-muted fw-bolder">Timestamp</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {events?.map((event: EVENT, index) => (
                                        <tr key={index} >
                                            <td className=" text-muted fw-bold">{index + 1}</td>

                                            <td className="ps-0 fw-bold text-muted">
                                                {/* <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'> */}
                                                {event?.equipment_name}
                                                {/* </a> */}
                                                {/* <span className='text-muted fw-bold d-block'>Description</span> */}
                                            </td>
                                            <td className=" text-muted fw-bold ps-0 ">
                                                {event?.facility_name}
                                            </td>
                                            <td className=" text-muted fw-bold ps-0">
                                                {event?.event_name}
                                            </td>

                                            <td className="ps-0" data-bs-toggle="tooltip" data-bs-placement="top" title={(new Date(event?.time)).toLocaleString()}  >
                                                {/* <Tooltip title={(new Date(event?.time)).toLocaleString()} placement="top"> */}

                                                <span className="text-muted fw-bold d-block ">
                                                    {CurrentDateToGivenTimeCalculation(event?.time)}
                                                </span>
                                                {/* </Tooltip> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                    {/* begin::Tap pane */}

                    {/* end::Tap pane */}
                    {/* begin::Tap pane */}

                    {/* end::Tap pane */}
                </div>
                {
                    events?.length == 0 &&
                    <div className='text-muted no-content py-3'>No Recent Events</div>
                }

            </div>
            {/* end::Body */}
        </div>
    );
};

export { RecentEvent };
