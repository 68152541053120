/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//import axios from 'axios'
import Tippy from "@tippyjs/react";
import _ from "lodash";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "tippy.js/dist/tippy.css"; // optional
import { KTSVG } from "../../../_metronic/helpers";
import { useRefresh } from "../../../_metronic/layout/components/ThemeProvider/RefreshButton";
import { useTheme } from "../../../_metronic/layout/components/ThemeProvider/ThemeProvider";
import CONSTANT from "../../Constant.json";
import {
  deleteEquipment,
  getEquipment,
  getFacility,
} from "../../modules/auth/redux/AuthCRUD";
import EquipmentInfoModel from "./EquipmentInfoModel";
import EquipmentModel from "./EquipmentModel";
const EquipmentPage = () => {
  const facility_id = localStorage?.getItem("selectedfacility");
  const { lightTheme } = useTheme();
  const role = localStorage.getItem("user_role");
  const history = useHistory();
  const { refresh } = useRefresh();
  const isDirect = facility_id ? false : true;
  const isAuthorized = useSelector(({ auth }) => auth.accessToken);
  const myRefname = useRef(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [facililtyList, setFacilityList] = useState([]);
  const [currFacility, setCurrFacility] = useState();
  const [currFacilityName, setCurrFacilityName] = useState();
  const [editData, setEditData] = useState({ id: "", name: "" });
  const [editMode, setEditMode] = useState(false);
  const [equipmentInfo, setEquipmentInfo] = useState(false);
  const [viewData, setViewData] = useState();
  const deletItem = (equipment_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: lightTheme ? "lightPopup" : "darkPopup",
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteEquipment(isAuthorized, facility_id, equipment_id)
            .then((response) => {
              // toast.success(CONSTANT.EQUIPMENT_DELETED_SUCCESSFULLY);
              setData(data.filter((el) => el.id !== equipment_id));
              Swal.fire({
                title: "Deleted!",
                text: CONSTANT.EQUIPMENT_HAS_BEEN_DELETED,
                showCancelButton: false,
                confirmButtonText: "OK",
                icon: "success",
                customClass: {
                  container: lightTheme ? "lightPopup" : "darkPopup",
                },
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
                customClass: {
                  container: lightTheme ? "lightPopup" : "darkPopup",
                },
              });
            });
        }
      })
      .catch((err) => Swal.fire("", err.message, "error"));
  };

  const handleEquipmentInfoClose = () => {
    setEquipmentInfo(false);
  };
  const handleEquipmentInfoOpen = () => {
    setEquipmentInfo(true);
  };
  const handleClose = () => {
    setShow(false);
    setEditMode(false);
  };
  const handleShow = () => {
    setShow(true);
    setEditMode(false);
  };
  const editActive = (item) => {
    setEditMode(true);
    setShow(true);
    setEditData(
      _.pick(
        item,
        "id",
        "name",
        "device_id",
        "device_serial",
        "equipment_type_id",
        "equipment_type_name",
        "np_voltage",
        "np_current",
        "np_power_kw",
        "np_hp",
        "np_rpm",
        "np_frequency",
        "np_sync_rpm"
      )
    );
  };

  const viewEquipment = (item) => {
    setEquipmentInfo(true);
    setViewData(
      _.pick(
        item,
        "id",
        "name",
        "device_id",
        "device_serial",
        "equipment_type_id",
        "equipment_type_name",
        "np_voltage",
        "np_current",
        "np_power_kw",
        "np_hp",
        "np_rpm",
        "np_frequency",
        "np_sync_rpm"
      )
    );
  };

  const handleFacilityChange = (event) => {
    const selectedValue = event.target.value;
    setCurrFacility(selectedValue);
  };

  const facility = localStorage?.getItem("selectedfacility");
  useEffect(() => {
    if (facility) {
      getEquipment(isAuthorized, facility)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
    }

    getFacility(isAuthorized)
      .then(({ data }) => {
        setFacilityList(data.data);
        if (facility_id) {
          const foundObject = data.data.find((obj) => obj.id === facility_id);
          setCurrFacility(foundObject.id);
          setCurrFacilityName(foundObject.name);
        }
      })
      .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
  }, []);

  useEffect(() => {
    if (currFacility) {
      const facilityId = currFacility ? currFacility : facility_id;
      getEquipment(isAuthorized, facilityId)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
    }
  }, [currFacility]);
  useEffect(() => {
    if (currFacility) {
      setCurrFacility(localStorage?.getItem("selectedfacility"));
      const facilityId = localStorage?.getItem("selectedfacility");
      getEquipment(isAuthorized, facilityId)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
    }
  }, [refresh]);

  return (
    <>
      {/* {console.log(currFacility, "curr facility before equip")} */}
      {editMode && currFacility ? (
        <>
          <EquipmentModel
            show={show}
            handleClose={handleClose}
            setData={setData}
            editData={editData}
            currFacility={currFacility}
            setEditData={setEditData}
            setCurrFacility={setCurrFacility}
          />
        </>
      ) : (
        <EquipmentModel
          show={show}
          handleClose={handleClose}
          setData={setData}
          currFacility={currFacility}
          setCurrFacility={setCurrFacility}
        />
      )}
      <EquipmentInfoModel
        show={equipmentInfo}
        handleClose={handleEquipmentInfoClose}
        viewData={viewData}
        setViewData={setViewData}
        setEditData={setEditData}
      />
      <div className="card">
        <div className="card-header border-0 pt-5">
          <div>
            {/* <label for="cars">Select the Facility</label> */}
            {/* <select
              selected={currFacilityName}
              className="form-select form-select-solid form-control-md"
              onChange={handleFacilityChange}
            >
              {isDirect && (
                <option value="" disabled selected>
                  Select facility
                </option>
              )}
              {facililtyList
                ? facililtyList.map((value) => {
                    return (
                      <option
                        key={value.id}
                        value={value.id}
                        selected={value.id === currFacility}
                      >
                        {value.name}
                      </option>
                    );
                  })
                : "N/A"}
              {}
            </select> */}
          </div>
          <div className="pt-2 pb-2"></div>
          <div className="card-toolbar">
            <a
              ref={myRefname}
              onClick={() => handleShow()}
              className="btn btn-sm btn-light-primary"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              {CONSTANT.NEW_EQUIPMENT}
            </a>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered table-row-gray-100 gs-0 gy-3">
              <thead>
                <tr className="fw-bolder text-muted bg-light">
                  <th className="ps-4 min-w-50px rounded-start">Id</th>
                  <th className="min-w-125px">Name</th>
                  <th className="min-w-125px">Esine device</th>
                  <th className="min-w-125px">Equipment type</th>
                  <th className="min-w-200px">created at</th>
                  {role == "Electrosine Employee" && (
                    <th className="min-w-50px">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => {
                    return (
                      <>
                        <tr style={{ border: "0 0 1px 0 solid #f5f8fa" }}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bolder text-hover-primary mb-1 ms-5 fs-6">
                                  {index + 1}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span
                                  className="text-dark fw-bolder text-hover-primary mb-1 fs-6 cursor-pointer"
                                  // onClick={() => viewEquipment(item)}
                                  onClick={() =>
                                    history.push(
                                      `/dashboard/facility/${facility}/equipment/${item.id}`
                                    )
                                  }
                                >
                                  {item.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                  {item.device_serial}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                  {item.equipment_type_name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="fs-7 fw-bolder text-dark">
                              <Moment format="MMM D YYYY">
                                {item.created_at}
                              </Moment>
                            </span>
                          </td>
                          {role == "Electrosine Employee" && (
                            <td>
                              <Tippy content={<span>Edit</span>}>
                                <a
                                  onClick={() => editActive(item)}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 edit-btn"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/art/art005.svg"
                                    className="svg-icon-3"
                                  />
                                </a>
                              </Tippy>
                              <Tippy content={<span>Delete</span>}>
                                <a
                                  onClick={() => deletItem(item.id)}
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm delete-btn"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-3"
                                  />
                                </a>
                              </Tippy>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td
                          className="text-dark"
                          colSpan="3"
                          style={{ textAlign: "center" }}
                        >
                          No Equipment found under current Facility
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquipmentPage;
