/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { useSelector } from "react-redux";
import { deleteCustomer, getCustomer } from "../../modules/auth/redux/AuthCRUD";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import CustomerTypeModel from "./CustomerTypeModel";
import Moment from "react-moment";
import CONSTANT from "../../Constant.json";
import { useTheme } from "../../../_metronic/layout/components/ThemeProvider/ThemeProvider";

const CustomerPage = () => {
  const isAuthorized = useSelector(({ auth }) => auth.accessToken);
  const { lightTheme } = useTheme();
  const myRefname = useRef(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [editData, setEditData] = useState({ id: "", name: "" });
  const [editMode, setEditMode] = useState(false);
  const deletItem = (values) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: lightTheme ? "#dedfe0" : "#00105e",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: lightTheme ? "lightPopup" : "darkPopup",
      },
      // background: lightTheme ? "#a3acd9" : "#203287",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(values)
          .then((response) => {
            // toast.success(CONSTANT.CUSTOMER_DELETED_SUCCESSFULLY);
            setData(data.filter((el) => el.id !== values));
            Swal.fire({
              title: "Deleted!",
              text: CONSTANT.CUSTOMER_HAS_BEEN_DELETED,
              showCancelButton: false,
              confirmButtonText: "OK",
              icon: "success",
              customClass: {
                container: lightTheme ? "lightPopup" : "darkPopup",
              },
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              timer: 1500,

              customClass: {
                container: lightTheme ? "lightPopup" : "darkPopup",
              },
            });
          });
      }
    });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setEditMode(false);
  };

  useEffect(() => {
    getCustomer(isAuthorized)
      .then(({ data }) => setData(data.data))
      .catch(() => console.log(CONSTANT.CUSTOMER_DATA_NOT_FOUND));
  }, []);

  return (
    <>
      {editMode ? (
        <CustomerTypeModel
          show={show}
          handleClose={handleClose}
          setData={setData}
          editData={editData}
          setEditData={setEditData}
        />
      ) : (
        <CustomerTypeModel
          show={show}
          handleClose={handleClose}
          setData={setData}
        />
      )}

      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column"></h3>
          <div className="card-toolbar">
            <a
              ref={myRefname}
              onClick={() => handleShow()}
              className="btn btn-sm btn-light-primary"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              {CONSTANT.NEW_CUSTOMER}
            </a>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table align-middle table-row-bordered table-row-gray-100 gs-0 gy-3">
              <thead>
                <tr className="fw-bolder text-muted bg-light">
                  <th className="ps-4 min-w-150px rounded-start">Id</th>
                  <th className="min-w-325px">Name</th>
                  <th className="min-w-150px">created at</th>
                  <th className="min-w-100px rounded-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => {
                    return (
                      <>
                        <tr style={{ border: "0 0 1px 0 solid #f5f8fa" }}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bolder text-hover-primary mb-1 ms-5 fs-6">
                                  {index + 1}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                  {item.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="fs-7 fw-bolder text-dark">
                              <Moment format="MMM D YYYY">
                                {item.created_at}
                              </Moment>
                            </span>
                          </td>
                          <td>
                            <Tippy content={<span>Delete</span>}>
                              <a
                                onClick={() => deletItem(item.id)}
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm delete-btn"
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen027.svg"
                                  className="svg-icon-3"
                                />
                              </a>
                            </Tippy>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          {/* No customer data found */}
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerPage;
