/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import EnergyPage from './EnergyPage'
import CONSTANT from '../../Constant.json'

const EnergyWrapper = () => {
    useEffect(() => {
        const customer_name = localStorage.getItem('customer_name')
        // document.title = `${customer_name} | ${CONSTANT.ENERGY}`
    }, []);

    return (
        <>
            <PageTitle>{CONSTANT.ENERGY}</PageTitle>
            <EnergyPage />
        </>
    )
}

export default EnergyWrapper
