

interface ThemeDesignProps {
  lightTheme: boolean;
}

export default function ThemeDesign({ lightTheme }: ThemeDesignProps) {
  const elements: NodeListOf<Element> = document.querySelectorAll(".card");
  const dropdownElements: NodeListOf<Element> = document.querySelectorAll("select");
  const menuElements: NodeListOf<Element> = document.querySelectorAll(".menu-theme");


  // Loop through each element and update its class
  elements.forEach((element) => {
    // Remove the opposite theme class
    element.classList.remove(lightTheme ? 'darkCard' : 'lightCard');
    // Add the current theme class
    element.classList.add(lightTheme ? 'lightCard' : 'darkCard');
  });

  dropdownElements.forEach((element) => {
    // Remove the opposite theme class
    element.classList.remove(lightTheme ? 'darkDropdown' : 'lightDropdown');
    // Add the current theme class
    element.classList.add(lightTheme ? 'lightDropdown' : 'darkDropdown');
  });
  menuElements.forEach((element) => {
    // Remove the opposite theme class
    element.classList.remove(lightTheme ? 'menuDark' : 'menuLight');
    // Add the current theme class
    element.classList.add(lightTheme ? 'menuLight' : 'menuDark');
  });
}
