/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import EquipmentPage from "./EquipmentPage";
import CONSTANT from '../../Constant.json'

const EquipmentWrapper = () => {
  const [data, setData] = useState();

  return (
    <>
      <PageTitle>
        {CONSTANT.EQUIPMENTS}
      </PageTitle>
      <EquipmentPage data={data} setData={setData} />
    </>
  );
};

export default EquipmentWrapper;
