/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { EquipmentDetail } from "../../../_metronic/partials/widgets/equipmentDashboard/EquipmentDetail";
import { Sparkline } from "../../../_metronic/partials/widgets/equipmentDashboard/Sparkline";
import { EquipmentDashboardGraph } from "../../../_metronic/partials/widgets/mixed/EquimentDashboardGraph";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getEquipmentAnalysis,
  getEquipmentDetailData,
  getEquipmentSparklineGraphData,
} from "../../modules/auth/redux/AuthCRUD";
import { FFTGraph } from "../../../_metronic/partials/widgets/mixed/EquipmentGraph";
import PlotlyGraph from "../../../_metronic/partials/widgets/mixed/PlotlyGraph";
import PlotlyGraph2 from "../../../_metronic/partials/widgets/mixed/PlotlyGraphh2";
import { useRefresh } from "../../../_metronic/layout/components/ThemeProvider/RefreshButton";
import EsaWaveformGraph from "../../../_metronic/partials/widgets/equipmentDashboard/EsaWaveformGraph";
import { EquipmentAnalysis } from "../../../_metronic/partials/widgets/equipmentDashboard/EquipmentAnalysis";

type EquipmentDetails = {
  id: string;
  device_id: string;
  name: string;
  facility_id: string;
  equipment_type_id: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  np_voltage: number;
  np_current: number;
  np_power_kw: number;
  np_hp: number;
  np_rpm: number;
  np_sync_rpm: number;
  np_frequency: number;
  facility_name: string;
  device_serial: string;
  equipment_type: string;
};
type EquipmentAnalysisDetails = {
  current: string;
  current_imbalance: string;
  current_thd: string;
  last_updated_epoch: number;
  estimated_rpm: string;
  frequency: string;
  power_factor: string;
  power_kva: string;
  power_kvar: string;
  power_kw: string;
  v_crest_factor: string;
  voltage: string;
};
type params = {
  facilityId: string;
  equipmentId: string;
};
const EquipmentDashboardPage: FC = () => {
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const { refresh } = useRefresh();

  const [equipmentCardData, setEquipmentCardData] = useState<EquipmentDetails>(
    {} as EquipmentDetails
  );
  const [equipmentAnalysisCardData, setEquipmentAnalysisCardData] =
    useState<EquipmentAnalysisDetails>({} as EquipmentAnalysisDetails);

  const [sparklineChartData, setSparklineChartData] = useState([]);
  const { facilityId, equipmentId } = useParams<params>();

  useEffect(() => {
    if (facilityId != null) {
      getEquipmentDetailData(isAuthorized, facilityId, equipmentId)
        .then(({ data }) => {
          setEquipmentCardData(data.data);
        })
        .catch(() => console.log("Equipment not found"));
    }
    getEquipmentAnalysis(isAuthorized, facilityId, equipmentId)
      .then(({ data }) => {
        setEquipmentAnalysisCardData(data.data);
      })
      .catch(() => console.log("No Recent Analysis found"));

    getEquipmentSparklineGraphData(isAuthorized, facilityId, equipmentId)
      .then(({ data }) => {
        setSparklineChartData(data.data);
      })
      .catch(() => console.log("No Recent Analysis found"));
  }, [equipmentId, refresh]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-4">
          <EquipmentDetail
            className="card-xl-stretch mb-xl-6"
            equipmentCardData={equipmentCardData}
          />
        </div>
        <div className="col-xl-4">
          <EquipmentAnalysis
            className="card-xl-stretch mb-xl-8"
            equipmentAnalysisCardData={equipmentAnalysisCardData}
          />
        </div>

        {/* <div className="col-xl-2">
          
          <div className="card card-xl-stretch mb-xl-8 fw-bold fs-1 align-items-center trafficLight   ">
            <p
              className={`h-25 d-flex justify-content-center mt-4  align-items-center rounded-circle w-50 green  `}
            ></p>
            <p
              className={`h-25 d-flex justify-content-center align-items-center rounded-circle w-50 yellow  `}
            ></p>
            <p
              className={`h-25 d-flex justify-content-center align-items-center rounded-circle w-50 orange  `}
            ></p>
            <p
              className={`h-25 d-flex justify-content-center align-items-center rounded-circle w-50 red  `}
            ></p>
          </div>
       
        </div> */}
        <div className="col-xl-4">
          <Sparkline
            className="card-xl-stretch mb-5 mb-xl-8"
            items={5}
            sparklineChartData={sparklineChartData}
          />
        </div>
      </div>
      {/* end::Row */}
      <div className="row g-5 gx-xxl-12">
        <div className="col-xxl-12">
          <EsaWaveformGraph
            className="mb-5 mb-xl-8"
            facilityId={facilityId}
            equipmentId={equipmentId}
            isAuthorized={isAuthorized}
            equipment={equipmentCardData?.name}
            facility={equipmentCardData?.facility_name}
          />
        </div>

        <div className="col-xxl-12">
          {/* <EquipmentDashboardGraph
            className="mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="170px"
            facilityId={facilityId}
            equipmentId={equipmentId}
            isAuthorized={isAuthorized}
          /> */}
          {/* <FFTGraph
            className="mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="170px"
            facilityId={facilityId}
            equipmentId={equipmentId}
            isAuthorized={isAuthorized}
          /> */}

          {/* <PlotlyGraph
            className="mb-5 mb-xl-8"
            facilityId={facilityId}
            equipmentId={equipmentId}
            isAuthorized={isAuthorized}
          /> */}
          <PlotlyGraph2
            className="mb-5 mb-xl-8"
            facilityId={facilityId}
            equipmentId={equipmentId}
            isAuthorized={isAuthorized}
          />
        </div>
      </div>
    </>
  );
};
const EquipmentDashboardWrapper: FC = () => {
  const intl = useIntl();
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | Dashboard`;
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.EQUIPMENT.DASHBOARD" })}
      </PageTitle>
      <EquipmentDashboardPage />
    </>
  );
};

export { EquipmentDashboardWrapper };
