/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { SparklineChart } from "./SparklineChart";
import { getTimezoneAbbreviation } from "../../../../app/utils/GetTimeZoneName";
import moment from "moment";

type Props = {
  className: string;
  items?: number;
  sparklineChartData: any;
};

const Sparkline: React.FC<Props> = ({
  className,
  items = 6,
  sparklineChartData,
}) => {
  const extractValues = (array: any, keys: any) => {
    return array.map((item: any) =>
      keys.map((key: any) =>
        key == "epoch"
          ? // new Date(utcEpochToLocalEpoch(item[key])).toLocaleString()
            // new Date(item[key] * 1000).toLocaleString() +
            moment(item[key] * 1000).format("YYYY-MM-DD HH:mm:ss") +
            " " +
            getTimezoneAbbreviation(new Date().getTimezoneOffset())
          : Number(item[key]).toFixed(2)
      )
    );
  };

  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-1">
            Equipment History
          </span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Latest tech trends</span> */}
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
            {/* 7days */}
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>

      {sparklineChartData?.length > 0 ? (
        <div className="card-body pt-5  ">
          <>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted "
                  >
                    Voltage
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>John, Pat, Jimmy</span> */}
                </div>
                <SparklineChart
                  className="card-xl-stretch pt-5 "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, ["voltage"]).flat(),
                    "Voltage",
                    "V",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+4500$</span> */}
              </div>
            </div>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted "
                  >
                    Current
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>Mark, Rowling, Esther</span> */}
                </div>
                {/* <StatisticsWidget4 /> */}
                <SparklineChart
                  className="card-xl-stretch "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, ["current"]).flat(),
                    "Current",
                    "A",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+82$</span> */}
              </div>
            </div>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted"
                  >
                    Power KW
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>Mark, Rowling, Esther</span> */}
                </div>
                {/* <StatisticsWidget4 /> */}
                <SparklineChart
                  className="card-xl-stretch "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, ["power_kw"]).flat(),
                    "Power KW",
                    "kW",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+82$</span> */}
              </div>
            </div>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted"
                  >
                    Power factor
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>Mark, Rowling, Esther</span> */}
                </div>
                {/* <StatisticsWidget4 /> */}
                <SparklineChart
                  className="card-xl-stretch "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, ["power_factor"]).flat(),
                    "Power factor",
                    "",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+82$</span> */}
              </div>
            </div>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted"
                  >
                    Current Imb
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>Mark, Rowling, Esther</span> */}
                </div>
                {/* <StatisticsWidget4 /> */}
                <SparklineChart
                  className="card-xl-stretch "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, [
                      "current_imbalance",
                    ]).flat(),
                    "Current Imb",
                    "%",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+82$</span> */}
              </div>
            </div>
            <div className="d-flex align-items-sm-center mb-7">
              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                <div className="flex-grow-1 me-2">
                  <div
                    // href="#"
                    className="text-gray-800 text-hover-primary fs-6 fw-bolder text-muted "
                  >
                    Current THD
                  </div>
                  {/* <span className='text-muted fw-bold d-block fs-7'>Mark, Rowling, Esther</span> */}
                </div>
                {/* <StatisticsWidget4 /> */}
                <SparklineChart
                  className="card-xl-stretch "
                  // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                  color="danger"
                  change="d"
                  description="Metering data"
                  data={[
                    extractValues(sparklineChartData, ["epoch"]).flat(),
                    extractValues(sparklineChartData, ["current_thd"]).flat(),
                    "Current THD",
                    "%",
                  ]}
                />
                {/* <span className='badge badge-light fw-bolder my-2'>+82$</span> */}
              </div>
            </div>
          </>
        </div>
      ) : (
        <div className="card-body pt-5 d-flex justify-content-center align-items-center text-muted fs-5 fw-bold">
          No historical data available{" "}
        </div>
      )}

      {/* end::Body */}
    </div>
  );
};

export { Sparkline };
