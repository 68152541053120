import React from "react";
import { Modal } from "react-bootstrap-v5";

type Props = {
  show: boolean;
  handleClose: () => void;
  setViewData: any;
  viewData: any;
};

const EquipmentInfoModel: React.FC<Props> = ({
  show,
  handleClose,
  setViewData,
  viewData
}) => {
  // console.log('inside the post modal, the facility Id changes to ', facility_id);

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      onHide={handleClose}
      role="dialog"
      show={show}
      dialogClassName="modal-dialog-centered mw-600px h-auto"
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {(viewData && viewData.name) ? viewData.name : ''} Details
          </h5>
        </div>
        <div className="modal-body">
          <div className="stepper-nav">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Current:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_current) ? viewData.np_current : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP RPM:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_rpm) ? viewData.np_rpm : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Frequency:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_frequency) ? viewData.np_frequency : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Horse Power:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_hp) ? viewData.np_hp : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Power (Kilo Watt):
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_power_kw) ? viewData.np_power_kw : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Sync RPM:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_sync_rpm) ? viewData.np_sync_rpm : ''} </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-stack">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      NP Voltage:
                    </label>
                    <div className="pt-2 pb-2"> {(viewData && viewData.np_voltage) ? viewData.np_voltage : ''} </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn btn-light-primary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EquipmentInfoModel;
