import React, { useState, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik, Field, Form, Formik } from 'formik'
import { getUser, editUser } from '../../modules/auth/redux/AuthCRUD'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import CONSTANT from '../../Constant.json'

const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required(CONSTANT.FIRST_NAME_IS_REQUIRED),
  last_name: Yup.string().required(CONSTANT.LAST_NAME_IS_REQUIRED)
})

export interface IProfileDetails {
  id: string
  email: string
  first_name: string
  last_name: string
  role_id: string
}

export const initialValues: IProfileDetails = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  role_id: '',
}
const UserProfile: React.FC = () => {
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken)
  const formikRef: any = useRef();
  const [loading, setLoading] = useState(false)

  const submitStep = (values: any) => {
    console.log('values', values);
    editUser(isAuthorized, values.id, values).then((data) => {
      if (data.status === 200) {
        toast.success(CONSTANT.USER_PROFILE_UPDATED_SUCCESSFULLY)
      } else {
        toast.error(CONSTANT.USER_PROFILE_UPDATED_FAILED)
      }
    }).catch(() => console.log('not valid data'))
  };

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values: any) => { },
  })

  useEffect(() => {
    getUser(isAuthorized).then((data) => {
      if (data.status === 200) {
        const preFilledData = {
          id: data.data.data.id,
          email: data.data.data.email,
          first_name: data.data.data.first_name,
          last_name: data.data.data.last_name,
          role_id: data.data.data.role.id
        };
        for (let i = 0; i < Object.keys(preFilledData).length; i++) {
          formikRef.current.setFieldValue(
            Object.keys(preFilledData)[i],
            Object.values(preFilledData)[i]
          );
        }
      }
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <Formik validationSchema={profileDetailsSchema}
          initialValues={initialValues}
          onSubmit={submitStep}
          innerRef={formikRef} >
          <Form>
            <Field
              className="form-control form-control-lg form-control-solid"
              name="id"
              type="hidden"
            />
            <Field
              className="form-control form-control-lg form-control-solid"
              name="email"
              type="hidden"
            />
            <Field
              className="form-control form-control-lg form-control-solid"
              name="role_id"
              type="hidden"
            />
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <Field
                        className="form-control form-control-lg form-control-solid"
                        name="first_name"
                        type="text"
                      />
                      {formik.touched.first_name && formik.errors.first_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.first_name}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 fv-row'>
                      <Field
                        className="form-control form-control-lg form-control-solid"
                        name="last_name"
                        type="text"
                      />
                      {formik.touched.last_name && formik.errors.last_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.last_name}</div>
                        </div>
                      )}
                    </div>
                    <div className='d-flex justify-content-end py-6 px-9'>
                      <button type='submit' className='btn btn-primary model-submit-btn' disabled={loading}>
                        {!loading && 'Save Changes'}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default UserProfile