/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget10,
  TablesWidget5
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {






  return (
    <>
      <div className='p-0'>

        {/* begin::Row */}
        {/* <div className='dashboardCarousel' >
          <div className='carouselBtn'
          >
            <button className="carousel-control-prev position-relative " type="button" onClick={handlePrev}>
              <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
          </div>


          <div className="carousel-container" style={{ width: "90%" }}>
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
              <div className="carousel-inner">
                {[...Array(Math.ceil(slides.length / groupSize))].map((_, groupIndex) => (
                  <div key={groupIndex} className={`carousel-item ${groupIndex === activeGroupIndex ? 'active' : ''}`}>
                    <div className="row">
                      {slides.slice(groupIndex * groupSize, groupIndex * groupSize + groupSize).map((slide) => (
                        <div key={slide.id} className="col">
                          <StatisticsWidget5
                            className='card-xl-stretch mb-xl-8'
                            // svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                            color='danger'
                            iconColor='white'
                            title={slide.name}
                            description='Metering data'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className='carouselBtn right'
          >
            <button className="carousel-control-next bg-dark position-relative rightBtn" type="button" onClick={handleNext}>
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div> */}
      </div>



      <div className='row g-5 gx-xxl-12'>
        {/* <div className='col-xxl-12'>
   

          <div className='row g-5 g-xl-8'>
            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
                color='danger'
                iconColor='white'
                title='Test'
                description='test data'
              />
            </div>

            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
                color='primary'
                iconColor='white'
                title='Test-1'
                description='Test Data'
              />
            </div>

            <div className='col-xl-4'>
              <StatisticsWidget5
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/graphs/gra005.svg'
                color='success'
                iconColor='white'
                title='Test-2'
                description='Test Data'
              />
            </div>

          </div>
        </div> */}
        {/* <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}

        <div className='col-xxl-12'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div>
      {/* end::Row */}
      <div className='row g-5 gx-xxl-12'>
        {/* <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div> */}

        <div className='col-xxl-12'>
          <MixedWidget10 className='mb-5 mb-xl-8' chartColor='primary' chartHeight='170px' />
          {/* <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        /> */}
        </div>
      </div>
      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
       <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div> 
    </div>*/}
      {/* end::Row */}

      {/* begin::Row */}
      {/*  <div className='row gy-5 g-xl-8'>
       <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
       <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div> 
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div> 
    </div> */}
      {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      {/* end::Row */}
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  useEffect(() => {
    const customer_name = localStorage.getItem('customer_name')
    // document.title = `${customer_name} | Dashboard`
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
