/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { PageTitle } from "../../../_metronic/layout/core";
import { useSelector } from "react-redux";
import UserPage from "./UserPage";
import CONSTANT from "../../Constant.json";

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: "Dashboard",
    path: "/dashboard",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

interface ICustomer {
  name: string;
}

const inits: ICustomer = {
  name: "",
};
const customerSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const UserWrapper = () => {
  const intl = useIntl();
  const myRefname = useRef(null);

  const [initValues, setInitialValues] = useState({ name: "" });
  const isAuthorized = useSelector(({ auth }) => auth.accessToken);
  const [data, setData] = useState();
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | Users`;
  }, []);
  return (
    <>
      <PageTitle>{"Users"}</PageTitle>
      <UserPage data={data} setData={setData} />
    </>
  );
};

export default UserWrapper;
