/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { KTSVG } from "../../../helpers";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";
import clsx from "clsx";
import { useTheme } from "../../../layout/components/ThemeProvider/ThemeProvider";

type Props = {
  className: string;
  svgIcon?: string;
  color: string;
  change: string;
  description: string;
  data?: any;
};

const SparklineChart: React.FC<Props> = ({
  className,
  svgIcon,
  color,
  change,
  description,
  data,
  // data,
}) => {
  const { lightTheme } = useTheme();
  const chartRef = useRef<HTMLDivElement | null>(null);
  console.log("data", data);
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = 57;
    const labelColor = getCSSVariableValue("--bs-gray-800");
    const baseColor = getCSSVariableValue("--bs-" + color);
    const lightColor = getCSSVariableValue("--bs-light-" + color);

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        labelColor,
        baseColor,
        lightColor,
        data,
        lightTheme
      )
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, color, data]);

  return (
    <div
      className={`card ${className} ${lightTheme ? "lightCard" : "darkCard"}`}
    >
      {/* begin::Body */}
      <div className="card-body p-0">
        <div
          ref={chartRef}
          className="statistics-widget-4-chart card-rounded-bottom"
          style={{ height: "50px" }}
        ></div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { SparklineChart };

function getChartOptions(
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string,
  data: any,
  lightTheme: boolean
): ApexOptions {
  return {
    series: [
      {
        name: data[2],
        data: [...data[1].reverse()],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "line",
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [lightTheme ? "#06C" : "#78a9ff"],
    },
    xaxis: {
      categories: [...data[0].reverse()],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: lightTheme ? "#06C" : "#78a9ff",
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      // max: 60,
      labels: {
        show: false,
        style: {
          colors: lightTheme ? "#06C" : "#78a9ff",
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + " " + data[3];
        },
      },
    },
    colors: [lightTheme ? "#06C" : "#78a9ff"],
    markers: {
      colors: [lightTheme ? "#06C" : "#78a9ff"],
      strokeColors: [lightTheme ? "#06C" : "#78a9ff"],
      strokeWidth: 3,
    },
  };
}
