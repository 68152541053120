export const CurrentDateToGivenTimeCalculation = (time) => {
  if (!time) {
    return "Invalid time";
  }

  const currentTime = new Date();
  const givenTime = new Date(time);

  if (isNaN(currentTime.getTime()) || isNaN(givenTime.getTime())) {
    return "Invalid time format";
  }

  const difference = currentTime - givenTime;
  let final = difference / 1000;

  if (final <= 60) {
    return "Just Now";
  } else if (final <= 60 * 60) {
    let minutes = Math.floor(final / 60);
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else if (final <= 60 * 60 * 24) {
    let hours = Math.floor(final / (60 * 60));
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (final <= 60 * 60 * 24 * 30) {
    let days = Math.floor(final / (60 * 60 * 24));
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (final <= 60 * 60 * 24 * 30 * 12) {
    let months = Math.floor(final / (60 * 60 * 24 * 30));
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else {
    let years = Math.floor(final / (60 * 60 * 24 * 30 * 12));
    return `${years} ${years === 1 ? "year" : "years"} ago`;
  }
};
