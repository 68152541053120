import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import {
  postEquipmentType,
  getEquipmentType,
  editEquipmentType
} from '../../modules/auth/redux/AuthCRUD'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import CONSTANT from '../../Constant.json'
import { useTheme } from '../../../_metronic/layout/components/ThemeProvider/ThemeProvider'

type Props = {
  show: boolean
  handleClose: () => void
  setData: any
  editData: any
  setEditData: any
}
interface IEquipmentType {
  name: string
}

const inits: IEquipmentType = {
  name: '',
}

const EquipmentTypeModel: React.FC<Props> = ({ show, handleClose, setData, editData, setEditData }) => {
  let equipmentTypeSchema = Yup.object().shape({
    name: Yup.string().required(CONSTANT.EQUIPMENTTYPE_NAME_IS_REQUIRED),
  })

  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken)
  const [options, setOptions] = useState()
  const [initValues, setInitialValues] = useState(inits)
  const formikRef: any = useRef()
  const { lightTheme, toggleTheme, themeStyles } = useTheme();

  const submitStep = (values: IEquipmentType, actions: FormikValues) => {
    if (editData && editData.id) {
      editEquipmentType(isAuthorized, editData.id, values).then((data) => {
        if (data.status === 200) {
          actions.resetForm()
          // toast.success(CONSTANT.EQUIPMENTTYPE_EDITED_SUCCESSFULLY);
          handleClose()
          getEquipmentType(isAuthorized)
            .then(({ data }) => {
              setData(data.data)
            })
            .catch(() => console.log(CONSTANT.EQUIPMENTTYPE_DATA_NOT_FOUND))
        } else {
          // toast.error(CONSTANT.EQUIPMENTTYPE_EDITED_FAILED)
        }
      })
        .catch(() => toast.error(CONSTANT.EQUIPMENTTYPE_EDITED_FAILED))
    } else {
      postEquipmentType(isAuthorized, values).then((data) => {
        if (data.status === 200) {
          // toast.success(CONSTANT.EQUIPMENTTYPE_ADDED_SUCCESSFULLY);
          actions.resetForm()
          handleClose()
          getEquipmentType(isAuthorized).then(({ data }) => {
            setData(data.data)
          })
        } else {
          // toast.error(CONSTANT.EQUIPMENTTYPE_ADDED_FAILED)
        }
      })
        .catch(() => console.log(CONSTANT.EQUIPMENTTYPE_ADDED_FAILED))
    }
  }
  useEffect(() => {
    getEquipmentType(isAuthorized).then(({ data }) => {
      setOptions(data.data)
    })
  }, [isAuthorized])
  useEffect(() => {
    if (editData) {
      for (let i = 0; i < Object.keys(editData).length; i++) {
        //console.log(Object.keys(editData)[i], Object.values(editData)[i])
        formikRef.current.setFieldValue(Object.keys(editData)[i], Object.values(editData)[i])
      }
    }
  }, [editData])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      onHide={handleClose}
      role='dialog'
      show={show}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      aria-hidden='true'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>

        <div className='modal-header'>
          <h5 className='modal-title'>
            {editData ? CONSTANT.EDIT_EQUIPMENTTYPE : CONSTANT.ADD_EQUIPMENTTYPE}
          </h5>
          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className='modal-body'>
          <div className='stepper-nav ps-lg-10'>
            <Formik
              validationSchema={equipmentTypeSchema}
              initialValues={initValues}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <>
                  <Form>
                    <div className='form-group'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            Equipment Type Name
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-3 pb-3'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='name'
                          type='text'
                        />
                      </div>
                      {errors.name && touched.name ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.name}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='text-center'>
                      <div className='modal-footer'>
                        {editData ? (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Save
                          </button>
                        ) : (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Submit
                          </button>
                        )}
                        <button
                          type='button'
                          onClick={() => handleClose()}
                          className='btn  model-cancel-btn'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EquipmentTypeModel
