/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import EquipmentTypePage from "./EquipmentTypePage";
import CONSTANT from "../../Constant.json";

const EquipmentTypeWrapper = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const customer_name = localStorage.getItem("customer_name");
    // document.title = `${customer_name} | ${CONSTANT.EQUIPMENTTYPE}`
  }, []);

  return (
    <>
      <PageTitle>{"Equipment-type"}</PageTitle>
      <EquipmentTypePage data={data} setData={setData} />
    </>
  );
};

export default EquipmentTypeWrapper;
