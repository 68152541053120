import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { Field, Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  postEquipment,
  getEquipment,
  getFacility,
  getDevice,
  getEquipmentType,
  editEquipment,
  getUnlinkDevice,
} from "../../modules/auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CONSTANT from '../../Constant.json'
import { useTheme } from "../../../_metronic/layout/components/ThemeProvider/ThemeProvider";
import Select from 'react-select'
import {
  SelectStyle,
  modalDropDownStyle,
} from "../../../_metronic/helpers/components/SelectStyle";
type Props = {
  show: boolean;
  handleClose: () => void;
  currFacility: any;
  setCurrFacility: any;
  setData: any;
  editData: any;
  setEditData: any;
};

const EquipmentModel: React.FC<Props> = ({
  show,
  handleClose,
  currFacility,
  setCurrFacility,
  setData,
  editData,
  setEditData,
}) => {
  let equipmentSchema = Yup.object().shape({
    facility_id: Yup.string().required(CONSTANT.FACILITY_IS_REQUIRED),
    name: Yup.string().required(CONSTANT.EQUIPMENT_NAME_IS_REQUIRED),
    device_id: Yup.string().required(CONSTANT.DEVICE_NAME_IS_REQUIRED),
    equipment_type_id: Yup.string().required(CONSTANT.EQUIPMENT_TYPE_IS_REQUIRED),
    np_current: Yup.number().required(CONSTANT.NP_CURRENT_IS_REQUIRED),
    np_hp: Yup.number().required(CONSTANT.NP_HP_IS_REQUIRED),
    np_power_kw: Yup.number().required(CONSTANT.NP_POWER_IS_REQUIRED),
    np_rpm: Yup.number().required(CONSTANT.NP_RPM_IS_REQUIRED),
    np_voltage: Yup.number().required(CONSTANT.NP_VOLTAGE_IS_REQUIRED),
    np_frequency: Yup.number().required(CONSTANT.NP_FREQUENCY_IS_REQUIRED),
    np_sync_rpm: Yup.number().required(CONSTANT.NP_SYNC_ROM_IS_REQUIRED),
  });

  const facility_id = currFacility;
  // console.log('inside the post modal, the facility Id changes to ', facility_id);

  const initLoad = {
    device_id: "",
    equipment_type_id: "",
    facility_id: `${currFacility}`,
    name: "",
    np_current: 0,
    np_hp: 0,
    np_power_kw: 0,
    np_rpm: 0,
    np_voltage: 0,
    np_frequency: 0,
    np_sync_rpm: 0,
  };
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const [options, setOptions] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);

  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const formikRef: any = useRef();
  const { lightTheme, toggleTheme, themeStyles } = useTheme();
  const [defaultValues, setDefaultValues] = useState<any>({});
  let facilityOptions = facilityList?.map((facility: any) => ({
    value: facility.id,
    label: facility.name,
  }));
  let equipmentTypeOptions = equipmentTypeList?.map((equipment: any) => ({
    value: equipment.id,
    label: equipment.name,
  }));
  let deviceOptions = deviceList?.map((device: any) => ({
    value: device.id,
    label: device.serial,
  }));
  const submitStep = (values: any, actions: FormikValues) => {
    if (editData && editData.id) {
      editEquipment(isAuthorized, values).then((data) => {
        if (data.status === 200) {
          actions.resetForm();
          // toast.success(CONSTANT.EQUIPMENT_EDITED_SUCCESSFULLY);
          handleClose();
          setCurrFacility(values.facility_id);
          if (values.facility_id === facility_id) {
            getEquipment(isAuthorized, facility_id)
              .then(({ data }) => {
                setData(data.data);
              })
              .catch(() => console.log(CONSTANT.EQUIPMENT_DATA_NOT_FOUND));
          }
        }
      });
    } else {
      postEquipment(isAuthorized, values)
        .then((data) => {
          if (data.status === 200) {
            // toast.success(CONSTANT.EQUIPMENT_ADDED_SUCCESSFULLY);
            actions.resetForm();
            handleClose();
            setCurrFacility(values.facility_id);
            if (values.facility_id === facility_id) {
              getEquipment(isAuthorized, facility_id)
                .then(({ data }) => {
                  setData(data.data);
                })
                .catch(() => console.log(CONSTANT.EQUIPMENT_DATA_NOT_FOUND));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setDefaultValues({})

  };

  useEffect(() => {
    if (facility_id) {
      getEquipment(isAuthorized, facility_id)
        .then(({ data }) => {
          setOptions(data.data);
        })
        .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
    }
    getFacility(isAuthorized).then(({ data }) => {
      setFacilityList(data.data);
    });

    getUnlinkDevice(isAuthorized).then(({ data }) => {
      setDeviceList(data.data);
    });
    getEquipmentType(isAuthorized)
      .then(({ data }) => {
        setEquipmentTypeList(data.data);
      })
      .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
  }, []);

  useEffect(() => {
    if (editData) {
      let values = { ...defaultValues };

      for (let i = 0; i < Object.keys(editData).length; i++) {
        formikRef.current.setFieldValue(
          Object.keys(editData)[i],
          Object.values(editData)[i]
        );
        if (facility_id) {

          values = {
            ...values,
            facility_id: facilityOptions?.filter(
              (d) => d.value == facility_id
            )[0],
          };
        }
        if (Object.keys(editData)[i]?.trim() == "device_id") {

          values = {
            ...values,
            device_id: deviceOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }
        if (Object.keys(editData)[i]?.trim() == "equipment_type_id") {
          values = {
            ...values,
            equipment_type_id: equipmentTypeOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }

      }
      setDefaultValues(values);

    }
  }, [editData, facilityList, equipmentTypeList, deviceList]);
  console.log("defaultValues", defaultValues, editData, deviceOptions);
  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      onHide={() => {
        handleClose();
        setDefaultValues({})
      }}
      role="dialog"
      show={show}
      dialogClassName="modal-dialog-centered mw-600px h-auto"
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>

        <div className="modal-header">
          <h5 className="modal-title">
            {editData ? CONSTANT.EDIT_EQUIPMENT : CONSTANT.ADD_EQUIPMENT + " in " + facilityOptions?.filter(
              (d) => d.value == facility_id
            )[0]?.label}

          </h5>
          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className="modal-body">
          <div className="stepper-nav">
            <Formik
              validationSchema={equipmentSchema}
              initialValues={initLoad}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <>
                  <Form>
                    <div className="form-group">

                      <div className="d-flex justify-content-between p-2">
                        <div className="d-flex flex-stack">
                          {/* begin::Label */}
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Select Device
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className="pt-2 pb-2">
                        <Field
                          as="select"
                          name="device_id"
                          className="form-select form-select-solid form-control-lg"
                        >
                          {/* <option value="">Select Device</option>
                          {deviceList
                            ? deviceList.map((value: any) => {
                              return (
                                <option key={value.id} value={value.id}>
                                  {value.serial}
                                </option>
                              );
                            })
                            : ""} */}
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              placeholder="Select Device"
                              styles={{
                                ...SelectStyle,
                                ...modalDropDownStyle,
                              }}
                              className="fs-8 react-select-dropdown modal-dropdown"
                              value={
                                defaultValues?.device_id ?? null
                              }
                              // onChange={e => setSelectedEquipment(e.value)}
                              options={deviceOptions}
                              onChange={(option: any) => {
                                setDefaultValues({
                                  ...defaultValues,
                                  device_id: option,
                                });
                                formikRef.current.setFieldValue(
                                  "device_id",
                                  option?.value
                                );
                              }}
                              onBlur={() => {
                                // handleBlur(e);
                                formikRef.current.setFieldTouched(
                                  "device_id",
                                  true
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Equipment Type
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          as="select"
                          name="equipment_type_id"
                          className="form-select form-select-solid form-control-lg"
                        >
                          {/* <option value="">Select Equipment Type</option>
                          {equipmentTypeList
                            ? equipmentTypeList.map((value: any) => {
                              return (
                                <option key={value.id} value={value.id}>
                                  {value.name}
                                </option>
                              );
                            })
                            : ""} */}
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              placeholder="Select Equipment Type"
                              styles={{
                                ...SelectStyle,
                                ...modalDropDownStyle,
                              }}
                              className="fs-8 react-select-dropdown modal-dropdown"
                              value={
                                defaultValues?.equipment_type_id ?? null
                              }
                              // onChange={e => setSelectedEquipment(e.value)}
                              options={equipmentTypeOptions}
                              onChange={(option: any) => {
                                setDefaultValues({
                                  ...defaultValues,
                                  equipment_type_id: option,
                                });
                                formikRef.current.setFieldValue(
                                  "equipment_type_id",
                                  option?.value
                                );
                              }}
                              onBlur={() => {
                                // handleBlur(e);
                                formikRef.current.setFieldTouched(
                                  "equipment_type_id",
                                  true
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-stack">
                            {/* begin::Label */}
                            <label className="form-label fw-bolder text-dark fs-6 mb-0">
                              Equipment Name
                            </label>
                            <div className="required"></div>
                          </div>
                        </div>
                        <div className="pt-2 pb-2">
                          <Field
                            className="form-control form-control-lg form-control-solid"
                            name="name"
                            type="text"
                          />
                        </div>
                        {errors.name && touched.name ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span style={{ color: "red" }} role="alert">
                                {errors.name}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Current
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_current"
                          className="form-control form-control-lg form-control-solid"
                        />
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP RPM
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_rpm"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Frequency
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_frequency"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Horse Power
                          </label>
                          <div className="required"></div>
                        </div>

                        <Field
                          type="number"
                          step="any"
                          name="np_hp"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Power (Kilo Watt)
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_power_kw"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Sync RPM
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_sync_rpm"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                      <div className="pt-2 pb-2">
                        <div className="d-flex justify-start">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            NP Voltage
                          </label>
                          <div className="required"></div>
                        </div>
                        <Field
                          type="number"
                          step="any"
                          name="np_voltage"
                          className="form-control form-control-lg form-control-solid"
                        ></Field>
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="modal-footer">
                        {editData ? (
                          <button type="submit" className="btn btn-primary model-submit-btn">
                            Edit
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary model-submit-btn">
                            Submit
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => handleClose()}
                          className="btn  model-cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EquipmentModel;
