/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import HealthPage from './HealthPage'
import CONSTANT from '../../Constant.json'

const HealthWrapper = () => {
    useEffect(() => {
        const customer_name = localStorage.getItem('customer_name')
        // document.title = `${customer_name} | ${CONSTANT.HEALTH}`
    }, []);

    return (
        <>
            <PageTitle>{CONSTANT.HEALTH}</PageTitle>
            <HealthPage />
        </>
    )
}

export default HealthWrapper
