// RefreshButton.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import './RefreshButton.scss'; // Import CSS file for styling
import RefreshSVG from '../../../../assets/refresh/refresh.svg'
import RefreshDarkSVG from '../../../../assets/refresh/refresh-dark.svg'
import { useTheme } from './ThemeProvider';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
interface RefreshContextProps {
    refresh: boolean;
    makeRefresh: () => void;

}

const RefreshContext = createContext<RefreshContextProps | undefined>(undefined)

const RefreshProvider = ({ children }: any) => {
    const [refresh, setRefresh] = useState<boolean>(false);
    const makeRefresh = () => {
        setRefresh((prevRefresh) => !prevRefresh);
    };
    return (
        <RefreshContext.Provider value={{ refresh, makeRefresh }}>
            {children}
        </RefreshContext.Provider>
    );
}
const useRefresh = (): RefreshContextProps => {
    const context = useContext(RefreshContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
};
interface RefreshButtonProps {
    onRefresh: () => void;
    refreshRotate: boolean
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ onRefresh, refreshRotate }) => {
    const { lightTheme } = useTheme()
    const { refresh, makeRefresh } = useRefresh();


    const handleRefresh = () => {
        makeRefresh()
        onRefresh(); // Call the provided refresh function
    };

    return (
        <img src={lightTheme ? RefreshSVG : RefreshDarkSVG} className={refreshRotate ? 'rotate' : ''} onClick={handleRefresh} width={"32px"} />

    );
};

export default RefreshButton;
export { RefreshProvider, useRefresh }