import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { signOut } from "./redux/AuthCRUD";
import * as auth from "./redux/AuthRedux";
import { toast } from "react-toastify";
export function Logout() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  useEffect(() => {
    signOut(isAuthorized);
    dispatch(auth.actions.logout());
    localStorage.removeItem("customer_name");
    localStorage.removeItem("user_role");
    localStorage.removeItem("selectedfacility");
    localStorage.removeItem("theme");
    window.location.reload();

    // document.location.reload()
  }, [dispatch]);

  return (
    <Switch>
      <Redirect to="/auth/login" />
    </Switch>
  );
}
