/* eslint-disable jsx-a11y/anchor-is-valid */
import "tippy.js/dist/tippy.css"; // optional

const NotificationPage = () => {
  return (
    <>
      <div className="card">Notification</div>
    </>
  );
};
export default NotificationPage;
