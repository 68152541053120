import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { postUsers, getUsers, editUser, getRoles, getCustomer } from '../../modules/auth/redux/AuthCRUD'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import CONSTANT from '../../Constant.json'
import { useTheme } from '../../../_metronic/layout/components/ThemeProvider/ThemeProvider'
import Select from 'react-select'
import {
  SelectStyle,
  modalDropDownStyle,
} from "../../../_metronic/helpers/components/SelectStyle";
type Props = {
  show: boolean
  handleClose: () => void
  setData: any
  editData: any
  setEditData: any
}

type Customer = {
  id: string
  name: string
}

type Option = {
  id: string
  name: string
}
interface ICustomer {
  customer_id: string
  first_name: string
  last_name: string
  email: string
  password: string
  role_id: string
}

const inits: ICustomer = {
  customer_id: '',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  role_id: ''
}

const UserTypeModel: React.FC<Props> = ({ show, handleClose, setData, editData, setEditData }) => {
  let customerSchema = Yup.object().shape({});
  const user_role = localStorage.getItem('user_role')
  customerSchema = Yup.object().shape({
    first_name: Yup.string().required(CONSTANT.FIRST_NAME_IS_REQUIRED),
    last_name: Yup.string().required(CONSTANT.LAST_NAME_IS_REQUIRED),
    email: Yup.string().email().required(CONSTANT.EMAIL_IS_REQUIRED),
    role_id: Yup.string().required(CONSTANT.ROLE_NAME_IS_REQUIRED),
  })

  if (!editData?.id) {
    customerSchema = customerSchema.shape({
      password: Yup.string().required(CONSTANT.PASSWORD_IS_REQUIRED)
    })
  }

  if (user_role === 'Electrosine Employee') {
    customerSchema = customerSchema.shape({
      customer_id: Yup.string().required(CONSTANT.CUSTOMER_IS_REQUIRED)
    })
  }

  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken)
  const [options, setOptions] = useState<Option[]>([])
  const [customers, setCustomersData] = useState<Customer[]>([])
  const [initValues, setInitialValues] = useState(inits)
  const formikRef: any = useRef()
  const { lightTheme, toggleTheme, themeStyles } = useTheme();
  const [defaultValues, setDefaultValues] = useState<any>({});
  let customerOptions = customers?.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));
  let roleOptions = options?.map((option) => ({
    value: option.id,
    label: option.name,
  }));
  const submitStep = (values: any, actions: FormikValues) => {
    if (editData && editData.id) {
      editUser(isAuthorized, editData.id, values).then((data) => {
        if (data.status === 200) {
          actions.resetForm()
          // toast.success(CONSTANT.USER_EDITED_SUCCESSFULLY)
          handleClose()
          getUsers(isAuthorized)
            .then(({ data }) => {
              setData(data.data)
            })
            .catch(() => console.log(CONSTANT.USER_DATA_NOT_FOUND))
        } else {
          // toast.error(CONSTANT.USER_EDITED_FAILED)
        }
      })
        .catch(() => toast.error(CONSTANT.USER_EDITED_FAILED))
    } else {
      postUsers(isAuthorized, values).then((data) => {
        if (data.status === 200) {
          // toast.success(CONSTANT.USER_ADDED_SUCCESSFULLY)
          actions.resetForm()
          handleClose()
          getUsers(isAuthorized).then(({ data }) => {
            setData(data.data)
          })
        } else {
          // toast.error(CONSTANT.USER_ADDED_FAILED)
        }
      })
        .catch((err) => {
          let error_message = (err.response.data.message === "Duplicate entry: Resource exists") ?
            CONSTANT.USER_EMAIL_IS_ALREADY_EXISTS : CONSTANT.USER_ADDED_FAILED;
          console.log(error_message)
        })
    }
    setDefaultValues({})

  }
  useEffect(() => {
    getRoles(isAuthorized).then((data) => {
      if (data.status === 200) {
        setOptions(data.data.data)
      } else if (data.status === 401) {

      }
    })
  }, [])

  // When user is electrosine employee then only customer list get for drop down
  useEffect(() => {
    if (user_role === CONSTANT.ELECTROSINE_EMPLOYEE) {
      getCustomer(isAuthorized).then((data) => {
        if (data.status === 200) {
          setCustomersData(data.data.data)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (editData && formikRef.current && editData.email.length > 0) {
      let values = { ...defaultValues };

      for (let i = 0; i < Object.keys(editData).length; i++) {
        formikRef.current.setFieldValue(Object.keys(editData)[i], Object.values(editData)[i])

        if (Object.keys(editData)[i]?.trim() == "customer_id") {
          values = {
            ...values,
            customer_id: customerOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }
        if (Object.keys(editData)[i]?.trim() == "role_id") {
          values = {
            ...values,
            role_id: roleOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }
      }
      setDefaultValues(values);

    }
  }, [editData, customers])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      onHide={() => {
        handleClose();
        setDefaultValues({})
      }}
      role='dialog'
      show={show}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      aria-hidden='true'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {editData && editData.email.length ? CONSTANT.EDIT_USER : CONSTANT.ADD_USER}
          </h5>
          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className='modal-body'>
          <div className='stepper-nav'>
            <Formik
              validationSchema={customerSchema}
              initialValues={initValues}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <>
                  <Form>
                    {(user_role === CONSTANT.ELECTROSINE_EMPLOYEE) ? (
                      <div className='form-group'>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex flex-stack'>
                            {/* begin::Label */}
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Customer</label>
                            <div className="required"></div>
                          </div>
                        </div>
                        <div className='pt-2 pb-2'>
                          <Field
                            // as='select'
                            name='customer_id'
                          // className='form-select form-select-solid form-control-lg'
                          >
                            {/* <option value=''>Select Customer</option>
                            {customers
                              ? customers.map((cvalue, index) => {
                                if (editData && cvalue.id === editData.customer_id)
                                  return (<option key={cvalue.id} value={cvalue.id} selected>{cvalue.name}</option>)
                                else
                                  return (<option key={cvalue.id} value={cvalue.id}>{cvalue.name}</option>)
                              })
                              : ''} */}
                            {({ field, form }: any) => (
                              <Select
                                {...field}
                                placeholder="Select Customer"
                                styles={{
                                  ...SelectStyle,
                                  ...modalDropDownStyle,
                                }}
                                className="fs-8 react-select-dropdown modal-dropdown"
                                value={
                                  defaultValues?.customer_id ?? null
                                }
                                // onChange={e => setSelectedEquipment(e.value)}
                                options={customerOptions}
                                onChange={(option: any) => {
                                  setDefaultValues({
                                    ...defaultValues,
                                    customer_id: option,
                                  });
                                  formikRef.current.setFieldValue(
                                    "customer_id",
                                    option?.value
                                  );
                                }}
                                onBlur={() => {
                                  // handleBlur(e);
                                  formikRef.current.setFieldTouched(
                                    "customer_id",
                                    true
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>

                        {errors.customer_id && touched.customer_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span style={{ color: 'red' }} role='alert'>
                                {errors.customer_id}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : ''}
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            First Name
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='first_name'
                          type='text'
                        />
                      </div>
                      {errors.first_name && touched.first_name ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.first_name}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0 '>
                            Last Name
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='last_name'
                          type='text'
                        />
                      </div>
                      {errors.last_name && touched.last_name ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.last_name}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Email</label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='email'
                          type='email'
                        />
                      </div>
                      {errors.email && touched.email ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.email}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {!editData ? (
                      <div className='form-group'>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex flex-stack'>
                            {/* begin::Label */}
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                              Password
                            </label>
                            <div className="required"></div>
                          </div>
                        </div>
                        <div className='pt-2 pb-2'>
                          <Field
                            className='form-control form-control-lg form-control-solid'
                            name='password'
                            type='password'
                          />
                        </div>
                        {errors.password && touched.password ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span style={{ color: 'red' }} role='alert'>
                                {errors.password}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Role</label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>
                        <Field
                          // as='select'
                          name='role_id'
                          className='form-select form-select-solid form-control-lg'
                        >
                          {/* <option value=''>Select Role</option>
                          {options
                            ? options.map((value, index) => {
                              return (
                                <option key={value.id} value={value.id}>
                                  {value.name}
                                </option>
                              )
                            })
                            : ''} */}
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              placeholder="Select Role"
                              styles={{
                                ...SelectStyle,
                                ...modalDropDownStyle,
                              }}
                              className="fs-8 react-select-dropdown modal-dropdown"
                              value={
                                defaultValues?.role_id ?? null
                              }
                              // onChange={e => setSelectedEquipment(e.value)}
                              options={roleOptions}
                              onChange={(option: any) => {
                                setDefaultValues({
                                  ...defaultValues,
                                  role_id: option,
                                });
                                formikRef.current.setFieldValue(
                                  "role_id",
                                  option?.value
                                );
                              }}
                              onBlur={() => {
                                // handleBlur(e);
                                formikRef.current.setFieldTouched(
                                  "role_id",
                                  true
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      {errors.role_id && touched.role_id ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.role_id}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='text-center'>
                      <div className='modal-footer'>
                        {editData ? (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Save
                          </button>
                        ) : (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Submit
                          </button>
                        )}
                        <button
                          type='button'
                          onClick={() => {
                            handleClose();
                            setDefaultValues({})
                          }}
                          className='btn  model-cancel-btn'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UserTypeModel
