/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./equipment.css";
type EquipmentDetails = {
  id: string;
  device_id: string;
  name: string;
  facility_id: string;
  equipment_type_id: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  np_voltage: number;
  np_current: number;
  np_power_kw: number;
  np_hp: number;
  np_rpm: number;
  np_sync_rpm: number;
  np_frequency: number;
  facility_name: string;
  device_serial: string;
  equipment_type: string;
};

type Props = {
  className: string;
  items?: number;
  equipmentCardData: EquipmentDetails;
};

const EquipmentDetail: React.FC<Props> = ({
  className,
  items = 6,
  equipmentCardData,
}) => {
  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className=" fw-bolder text-dark fs-1">
            Equipment Details / Nameplate
          </span>
          {/* <span>{equipmentCardData?.facility_name}</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="d-flex card-body fs-5 fw-bold text-muted justify-content-center align-items-center ">
        <div className="d-flex">
          <div className="parameter">
            <div className="flex-grow-1 me-2 lh-lg parameterheading">
              Parameter
            </div>

            <div className="flex-grow-1 me-2 lh-lg parametername">
              Facility Name
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              Equipment Name
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              ESine Serial
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              Equipment Type
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              NP Current{" "}
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              NP Voltage{" "}
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">
              NP Frequency
            </div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">NP hp</div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parametername">NP Power</div>
            <hr />

            <div className="flex-grow-1 me-2 lh-lg parameterlastname">
              NP RPM
            </div>
          </div>
          {/* <div className="sepertorWidth">
            <div className="lh-lg">|</div>
            <hr />

            <div className="lh-lg" > |</div>
            <div className="lh-lg">|</div>
            <div className="lh-lg">|</div>
            <div className="lh-lg">|</div>      <div className="lh-lg">|</div>
            <div className="lh-lg">|</div>      <div className="lh-lg">|</div>
            <div className="lh-lg">|</div>      <div className="lh-lg">|</div>
            <div className="lh-lg">|</div>
          </div> */}
          <div>
            <div className="lh-lg ps-5 parameterheading">Value</div>

            <div className="lh-lg parametervalue">
              {equipmentCardData?.facility_name}
            </div>
            <hr />

            <div className="lh-lg parametervalue">
              {equipmentCardData?.name}
            </div>
            <hr />

            <div className="lh-lg parametervalue">
              {equipmentCardData?.device_serial}
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.equipment_type}
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.np_current} A
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.np_voltage} V
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.np_frequency} Hz
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.np_hp}
            </div>
            <hr />
            <div className="lh-lg parametervalue">
              {equipmentCardData?.np_power_kw} kW
            </div>
            <hr />
            <div className="lh-lg parameterlastvalue">
              {equipmentCardData?.np_rpm}
            </div>
          </div>
        </div>

        {/* <div className="d-flex align-items-sm-center mb-3">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">Facility Name</div>
            <div>{equipmentCardData?.facility_name}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">Equipment Name</div>
            <div>{equipmentCardData?.name}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">ESine Serial</div>
            <div>{equipmentCardData?.device_serial}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">Equipment Type</div>
            <div>{equipmentCardData?.equipment_type}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP Current </div>
            <div>{equipmentCardData?.np_current}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP Voltage </div>
            <div>{equipmentCardData?.np_voltage}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP Frequency</div>
            <div>{equipmentCardData?.np_frequency}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP hp</div>
            <div>{equipmentCardData?.np_hp}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center mb-4">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP Power</div>
            <div>{equipmentCardData?.np_power_kw}</div>
          </div>
        </div>
        <div className="d-flex align-items-sm-center ">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1 me-2">NP RPM</div>
            <div>{equipmentCardData?.np_rpm}</div>
          </div>
        </div> */}
      </div>
      {/* <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered table-row-gray-100 gs-0 gy-3">
            <thead>
              <tr className="fw-bolder text-muted bg-light">
                <th className="ps-4 rounded-start">Facility Name</th>
                <th className="">Equipment Name</th>
                <th className="">ESine Serial</th>
                <th className="">Equipment Type</th>
                <th className="">NP Current</th>
                <th className="">NP Voltage</th>
                <th className="">NP Frequency</th>
                <th className="">NP hp</th>
                <th className="">NP Power</th>
                <th className="pe-4 rounded-end">NP RPM</th>


              </tr>
            </thead>
            <tbody>

              <tr style={{ border: "0 0 1px 0 solid #f5f8fa" }}>
                <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.facility_name}

                      </span>
                    </div>
                  </div>
                </td>
                <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.name}

                      </span>
                    </div>
                  </div>
                </td>
                <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.device_serial}

                      </span>
                    </div>
                  </div>
                </td>

                <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.equipment_type}

                      </span>
                    </div>
                  </div>
                </td>
                <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_current}

                      </span>
                    </div>
                  </div>
                </td>      <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_voltage}

                      </span>
                    </div>
                  </div>
                </td>      <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_frequency}

                      </span>
                    </div>
                  </div>
                </td>      <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_hp}

                      </span>
                    </div>
                  </div>
                </td>      <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_power_kw}

                      </span>
                    </div>
                  </div>
                </td>      <td>

                  <div className="d-flex ">
                    <div className="d-flex flex-column">
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {equipmentCardData?.np_rpm}

                      </span>
                    </div>
                  </div>
                </td>

              </tr>



            </tbody>
          </table>
        </div>
      </div> */}
      {/* end::Body */}
    </div>
  );
};

export { EquipmentDetail };
