import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, useLocation, useParams } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from '../_metronic/layout/components/ThemeProvider/ThemeProvider'
import { RefreshProvider } from '../_metronic/layout/components/ThemeProvider/RefreshButton'
type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer />
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeProvider>
              <RefreshProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </RefreshProvider>
            </ThemeProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
