/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./equipment.css";
import moment from "moment";
import { getTimezoneAbbreviation } from "../../../../app/utils/GetTimeZoneName";
type EquipmentAnalysisDetails = {
  current: string;
  current_imbalance: string;
  current_thd: string;
  last_updated_epoch: number;
  estimated_rpm: string;
  frequency: string;
  power_factor: string;
  power_kva: string;
  power_kvar: string;
  power_kw: string;
  v_crest_factor: string;
  voltage: string;
};

type Props = {
  className: string;
  items?: number;
  equipmentAnalysisCardData: EquipmentAnalysisDetails;
};

const EquipmentAnalysis: React.FC<Props> = ({
  className,
  items = 6,
  equipmentAnalysisCardData,
}) => {
  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start  ">
          <div className=" fw-bolder text-dark fs-1">Latest Analysis</div>
        </h3>
        <div className="fs-4 fw-bold card-title align-items-start text-muted ">
          {equipmentAnalysisCardData &&
            moment(equipmentAnalysisCardData?.last_updated_epoch * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ) +
              " " +
              getTimezoneAbbreviation(new Date().getTimezoneOffset())}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="d-flex card-body fs-5 fw-bold text-muted justify-content-center align-items-center ">
        {equipmentAnalysisCardData ? (
          <div className="container   fs-5 fw-bold text-muted d-flex  ">
            <div className="row gx-2 gy-8 d-flex justify-content-between ">
              <div className="col-6 equipmentAnalysisSinglecard  ">
                {" "}
                <div className="flex-grow-1 lh-lg ">
                  {equipmentAnalysisCardData?.voltage} V <br />
                  Voltage
                </div>
              </div>
              <div className="col-6 equipmentAnalysisSinglecard ">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.current} A <br />
                  Current
                </div>
              </div>
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.frequency} Hz <br />
                  Frequency
                </div>
              </div>
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.power_factor} <br />
                  Power Factor
                </div>
              </div>{" "}
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.current_imbalance} %<br />
                  Current Imbalance
                </div>
              </div>{" "}
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.current_thd} %<br />
                  Current THD
                </div>
              </div>{" "}
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.power_kw} kW <br />
                  Power KW
                </div>
              </div>{" "}
              <div className="col-6 equipmentAnalysisSinglecard">
                {" "}
                <div className="flex-grow-1 me-2 lh-lg ">
                  {equipmentAnalysisCardData?.v_crest_factor} <br />
                  Voltage Crest Factor
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>No recent analysis available</div>
        )}
      </div>
    </div>
  );
};

export { EquipmentAnalysis };
