import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import {
  postDevice,
  getDevice,
  editDevice,
  getCustomer
} from '../../modules/auth/redux/AuthCRUD'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import CONSTANT from '../../Constant.json'
import { useTheme } from '../../../_metronic/layout/components/ThemeProvider/ThemeProvider'
import Select from 'react-select'
import {
  SelectStyle,
  modalDropDownStyle,
} from "../../../_metronic/helpers/components/SelectStyle";
type Props = {
  show: boolean
  handleClose: () => void
  setData: any
  editData: any
  setEditData: any
}

type Customer = {
  id: string
  name: string
}
interface IDevice {
  customer_id: string
  serial: string
}

const inits: IDevice = {
  customer_id: '',
  serial: '',
}

const DeviceModel: React.FC<Props> = ({ show, handleClose, setData, editData, setEditData }) => {
  let deviceSchema = Yup.object().shape({
    customer_id: Yup.string().required(CONSTANT.CUSTOMER_IS_REQUIRED),
    serial: Yup.string().required(CONSTANT.DEVICE_NAME_IS_REQUIRED),
  })

  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken)
  const [options, setOptions] = useState('')
  const [customers, setCustomersData] = useState<Customer[]>([])
  const [initValues] = useState(inits)
  const { lightTheme, toggleTheme, themeStyles } = useTheme();
  const [defaultValues, setDefaultValues] = useState<any>({});
  let customerOptions = customers?.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));
  const formikRef: any = useRef()
  const submitStep = (values: IDevice, actions: FormikValues) => {
    if (editData && editData.id) {
      editDevice(isAuthorized, editData.id, values).then((data) => {
        if (data.status === 200) {
          actions.resetForm()
          // toast.success(CONSTANT.DEVICE_EDITED_SUCCESSFULLY);
          handleClose()
          getDevice(isAuthorized)
            .then(({ data }) => {
              setData(data.data)
            })
            .catch(() => console.log(CONSTANT.DEVICE_DATA_NOT_FOUND))
        } else {
          // toast.error(CONSTANT.DEVICE_EDITED_FAILED)
        }
      })
        .catch(() => console.log(CONSTANT.DEVICE_EDITED_FAILED))
    } else {
      postDevice(isAuthorized, values).then((data) => {
        if (data.status === 200) {
          // toast.success(CONSTANT.DEVICE_ADDED_SUCCESSFULLY);
          actions.resetForm()
          handleClose()
          getDevice(isAuthorized).then(({ data }) => {
            setData(data.data)
          })
        } else {
          // toast.error(CONSTANT.DEVICE_ADDED_FAILED)
        }
      })
        .catch(() => console.log(CONSTANT.DEVICE_ADDED_FAILED))
    }
    setDefaultValues({})

  }
  useEffect(() => {
    getDevice(isAuthorized).then(({ data }) => {
      setOptions(data.data)
    })
  }, [isAuthorized])

  useEffect(() => {
    getCustomer(isAuthorized).then((data) => {
      if (data.status === 200) {
        setCustomersData(data.data.data)
      }
    })
  }, [isAuthorized])

  useEffect(() => {
    if (editData) {
      let values = { ...defaultValues };

      for (let i = 0; i < Object.keys(editData).length; i++) {
        //console.log(Object.keys(editData)[i], Object.values(editData)[i])
        formikRef.current.setFieldValue(Object.keys(editData)[i], Object.values(editData)[i])
        if (Object.keys(editData)[i]?.trim() == "customer_id") {
          values = {
            ...values,
            customer_id: customerOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }
      }
      setDefaultValues(values);

    }
  }, [editData, customers])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      onHide={() => {
        handleClose();
        setDefaultValues({})
      }}
      role='dialog'
      show={show}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      aria-hidden='true'
      aria-labelledby='contained-modal-title-vcenter'
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>

        <div className='modal-header'>
          <h5 className='modal-title'>
            {editData ? CONSTANT.EDIT_DEVICE : CONSTANT.ADD_DEVICE}
          </h5>
          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className='modal-body'>
          <div className='stepper-nav ps-lg-10'>
            <Formik
              validationSchema={deviceSchema}
              initialValues={initValues}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <>
                  <Form>
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Customer</label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-2 pb-2'>
                        <Field
                          as='select'
                          name='customer_id'
                          className='form-select form-select-solid form-control-lg'
                        >
                          {/* <option value=''>Select Customer</option>
                          {customers
                            ? customers.map((value, index) => {
                              return (
                                <option key={value.id} value={value.id}>
                                  {value.name}
                                </option>
                              )
                            })
                            : ''} */}
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              placeholder="Select Customer"
                              styles={{
                                ...SelectStyle,
                                ...modalDropDownStyle,
                              }}
                              className="fs-8 react-select-dropdown modal-dropdown"
                              value={
                                defaultValues?.customer_id ?? null
                              }
                              // onChange={e => setSelectedEquipment(e.value)}
                              options={customerOptions}
                              onChange={(option: any) => {
                                setDefaultValues({
                                  ...defaultValues,
                                  customer_id: option,
                                });
                                formikRef.current.setFieldValue(
                                  "customer_id",
                                  option?.value
                                );
                              }}
                              onBlur={() => {
                                // handleBlur(e);
                                formikRef.current.setFieldTouched(
                                  "customer_id",
                                  true
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>

                      {errors.customer_id && touched.customer_id ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.customer_id}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-stack mb-2'>
                          {/* begin::Label */}
                          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                            Serial Number
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className='pt-3 pb-3'>
                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='serial'
                          type='text'
                        />
                      </div>
                      {errors.serial && touched.serial ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span style={{ color: 'red' }} role='alert'>
                              {errors.serial}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className='text-center'>
                      <div className='modal-footer'>
                        {editData ? (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Save
                          </button>
                        ) : (
                          <button type='submit' className='btn btn-primary model-submit-btn'>
                            Submit
                          </button>
                        )}
                        <button
                          type='button'
                          onClick={() => {
                            handleClose();
                            setDefaultValues({})
                          }}
                          className='btn  model-cancel-btn'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeviceModel
