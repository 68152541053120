import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {Redirect, Switch, useHistory } from 'react-router-dom'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {toast} from 'react-toastify'
import {decodeToken} from 'react-jwt'

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.api.electrosine.io'
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/get-user`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`
export const REQUEST_SIGNOUT = `${API_URL}/logout`
export const GET_CUSTOMERS = `${API_URL}/customer`
export const POST_CUSTOMER = `${API_URL}/customer`
export const DELETE_VEHICAL = `${API_URL}/customer`
export const STATUS_UPDATE = `${API_URL}/customer-status-update`
export const UPDATE_VEHICALDATA = `${API_URL}/update-customer`
export const USERS = `${API_URL}/customer`
export const POST_USERS = `${API_URL}/add-user`
export const DELETE_USERS = `${API_URL}/admin/user`
export const USERS_STATUS_UPDATE = `${API_URL}/user-status-update`
export const USERS_DATA_UPDATE = `${API_URL}/update-user`
export const USER_ROLES = `${API_URL}/user-role`
export const FACILITY = `${API_URL}/customer`
export const EQUIPMENT = `${API_URL}/customer`
export const DEVICE = `${API_URL}/customer`
export const EQUIPMENT_TYPE = `${API_URL}/equipment_type`

// When any api got 401 response it will redirect to login page.
axios.interceptors.response.use((response) => {
  return response;
}, (error) => { // Anything except 2XX goes to here
  const status = error.response?.status || 500;
  if (status === 401) {
    localStorage.clear();
    return window.location.href = 'auth/login'  
  } else {
    return Promise.reject(error); // Delegate error to calling side
  }
});

// Server should return AuthModel
export function login(email: string, password: string, timezone:string) {
  return axios.post(LOGIN_URL, {email, password, timezone})
}
// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}
export function getUserByToken(data: any) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  const myDecodedToken: any = decodeToken(data)
  const datas = `${API_URL}/customer/${myDecodedToken.cuid}/user/${myDecodedToken.sub}`
  return axios.get(datas)
}
export function signOut(token: any) {
  axios
    .get(REQUEST_SIGNOUT, {headers: {Authorization: `Bearer ${token}`}})
    .then((data) => toast(data.data.message))
}
export function getCustomer(token: any) {
  console.log(token, 'token')
  return axios.get(GET_CUSTOMERS, {headers: {Authorization: `Bearer ${token}`}})
}

export function getCustomerDetails(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const CUSTOMER_DETAIL = `${GET_CUSTOMERS}/${myDecodedToken.cuid}`
  return axios.get(CUSTOMER_DETAIL, {headers: {Authorization: `Bearer ${token}`}})
}

export function postCustomer(token: any, value: any) {
  return axios.post(POST_CUSTOMER, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}
export async function deleteCustomer(token: any) {
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: `${DELETE_VEHICAL}/${token}`,
  }

  const response: AxiosResponse = await axios(config)
  return response
}
export async function updateCustomerStatus(token: any, value: any, status: boolean) {
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: STATUS_UPDATE,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id: `${value}`,
      status: `${!status}`,
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}
export async function editCustomer(token: any, id: any, value: string) {
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: UPDATE_VEHICALDATA,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {id: id, name: value},
  }

  const response: AxiosResponse = await axios(config)
  return response
}

// ###################### Start Of User Apis ################################
export function getUsers(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_USER = `${USERS}/${myDecodedToken.cuid}/user`
  return axios.get(GET_USER, {headers: {Authorization: `Bearer ${token}`}})
}

export function getUser(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_USER = `${USERS}/${myDecodedToken.cuid}/user/${myDecodedToken.sub}`
  return axios.get(GET_USER, {headers: {Authorization: `Bearer ${token}`}})
}

export function postUsers(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  value.customer_id = (value.customer_id !== "") ? value.customer_id : myDecodedToken.cuid
  return axios.post(`${USERS}/${value.customer_id}/user`, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteUser(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: `${USERS}/${myDecodedToken.cuid}/user/${value}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}

export async function updateUserStatus(token: any, value: any, status: boolean) {
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: USERS_STATUS_UPDATE,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id: `${value}`,
      status: `${!status}`,
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}
// user_id = myDecodedToken.sub
export async function editUser(token: any, id: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  value.customer_id = (value.customer_id !== "") ? value.customer_id : myDecodedToken.cuid
  const editValue = {
    id: id,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    role_id: value.role_id,
  }
  
  const config: AxiosRequestConfig = {
    method: 'put',
    url: `${USERS}/${myDecodedToken.cuid}/user/${myDecodedToken.sub}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: editValue,
  }
  
  const response: AxiosResponse = await axios(config)
  return response
}
// ###################### End Of User Apis ################################

// ###################### Start Of User Role Apis ################################
export function getRoles(token: any) {
  return axios.get(USER_ROLES, {headers: {Authorization: `Bearer ${token}`}})
}

export function postRole(token: any, value: any) {
  return axios.post(USER_ROLES, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteRole(token: any, value: any) {
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: `${USER_ROLES}/${value}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {id: value},
  }

  const response: AxiosResponse = await axios(config)
  return response
}
// ###################### End Of User Role Apis ################################

// ###################### Start Of Facility Apis ################################
export function getFacility(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_FACILITY = `${FACILITY}/${myDecodedToken.cuid}/facility`
  return axios.get(GET_FACILITY, {headers: {Authorization: `Bearer ${token}`}})
}

export function postFacility(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  value.customer_id = (value.customer_id !== "") ? value.customer_id : myDecodedToken.cuid
  const ADD_FACILITY = `${FACILITY}/${value.customer_id}/facility`
  return axios.post(ADD_FACILITY, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteFacility(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  const DELETE_FACILITY = `${FACILITY}/${myDecodedToken.cuid}/facility/${value}`
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: DELETE_FACILITY,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {id: value},
  }

  const response: AxiosResponse = await axios(config)
  return response
}
export async function updateFacility(token: any, value: any, status: boolean) {
  const myDecodedToken: any = decodeToken(token)
  const UPDATE_FACILITY = `${FACILITY}/${myDecodedToken.cuid}/facility/${value}`
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: UPDATE_FACILITY,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id: `${value}`,
      status: `${!status}`,
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}

export async function editFacility(token: any, id: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  value.customer_id = (value.customer_id !== "") ? value.customer_id : myDecodedToken.cuid
  const EDIT_FACILITY = `${FACILITY}/${value.customer_id}/facility/${id}`
  const config: AxiosRequestConfig = {
    method: 'put',
    url: EDIT_FACILITY,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: value.name,
      timezone: value.timezone,
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}

// ###################### End Of Facility Apis ################################



// ###################### Start Of Device Apis ################################
export function getDevice(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_DEVICE = `${DEVICE}/${myDecodedToken.cuid}/device`
  return axios.get(GET_DEVICE, {headers: {Authorization: `Bearer ${token}`}})
}
export function getUnlinkDevice(token: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_DEVICE = `${DEVICE}/${myDecodedToken.cuid}/unlink-devices`
  return axios.get(GET_DEVICE, {headers: {Authorization: `Bearer ${token}`}})
}


export function postDevice(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  const ADD_DEVICE = `${DEVICE}/${myDecodedToken.cuid}/device`
  value.customer_id = myDecodedToken.cuid
  return axios.post(ADD_DEVICE, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteDevice(token: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  const DELETE_DEVICE = `${DEVICE}/${myDecodedToken.cuid}/device/${value}`
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: DELETE_DEVICE,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {id: value}
  }; 

  const response: AxiosResponse = await axios(config);
  return response;
}
export async function updateDevice(token: any, value: any, status: boolean) {
  const myDecodedToken: any = decodeToken(token)
  const UPDATE_FACILITY = `${FACILITY}/${myDecodedToken.cuid}/facility/${value}`
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: UPDATE_FACILITY,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id: `${value}`,
      status: `${!status}`,
    }
  }; 

  const response: AxiosResponse = await axios(config);
  return response;
}

export async function editDevice(token: any, id: any, value: any) {
  const myDecodedToken: any = decodeToken(token)
  const EDIT_FACILITY = `${FACILITY}/${myDecodedToken.cuid}/facility/${id}`
  const config: AxiosRequestConfig = {
    method: 'put',
    url: EDIT_FACILITY,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: value.name,
      timezone: value.timezone
    }
  }; 

  const response: AxiosResponse = await axios(config);
  return response;
}
export function getDeviceThreshold(token: any,id:any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_DEVICE_THRESHOLD = `${API_URL}/device/id/${id}/event-threshold`
  return axios.get(GET_DEVICE_THRESHOLD, {headers: {Authorization: `Bearer ${token}`}})
}
export async function editDeviceThreshold(token: any,id:any,value:any) {
  const myDecodedToken: any = decodeToken(token)
  const PUT_DEVICE_THRESHOLD = `${API_URL}/device/id/${id}/event-threshold`

  const config: AxiosRequestConfig = {
    method: 'put',
    url: PUT_DEVICE_THRESHOLD,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      v_pct_chg_max: +value.v_pct_chg_max,
      i_pct_chg_max: +value.i_pct_chg_max,
    }
  }; 
  const response: AxiosResponse = await axios(config);
  return response;
}
export function getDeviceCalibration(token: any,id:any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_DEVICE_CALIBRATION = `${API_URL}/device/id/${id}/calibration`
  return axios.get(GET_DEVICE_CALIBRATION, {headers: {Authorization: `Bearer ${token}`}})
}
export async function edittDeviceCalibration(token: any,id:any,value:any) {
  const myDecodedToken: any = decodeToken(token)
  const PUT_DEVICE_CALIBRATION = `${API_URL}/device/id/${id}/calibration`

  const config: AxiosRequestConfig = {
    method: 'put',
    url: PUT_DEVICE_CALIBRATION,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      v1: +value.v1,
      i1: +value.i1,
      v2: +value.v2,
      i2: +value.i2,  
       v3:+value.v3,
      i3: +value.i3,
    }
  }; 
  const response: AxiosResponse = await axios(config);
  return response;
}



// ###################### End Of Device Apis ################################

// ###################### Start Of EquipmentType Apis ################################
export function getEquipmentType(token: any) {
  const GET_EQUIPMENT_TYPE = `${EQUIPMENT_TYPE}`
  return axios.get(GET_EQUIPMENT_TYPE, {headers: {Authorization: `Bearer ${token}`}})
}

export function postEquipmentType(token: any, value: any) {
  const ADD_EQUIPMENT_TYPE = `${EQUIPMENT_TYPE}`
  return axios.post(ADD_EQUIPMENT_TYPE, value, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteEquipmentType(token: any, value: any) {
  const DELETE_EQUIPMENT_TYPE = `${EQUIPMENT_TYPE}/${value}`
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: DELETE_EQUIPMENT_TYPE,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {id: value}
  }; 

  const response: AxiosResponse = await axios(config);
  return response;
}

export async function editEquipmentType(token: any, id: any, value: any) {
  const EDIT_EQUIPMENT_TYPE = `${EQUIPMENT_TYPE}/${id}`
  const config: AxiosRequestConfig = {
    method: 'put',
    url: EDIT_EQUIPMENT_TYPE,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: value.name,
      timezone: value.timezone
    }
  }; 

  const response: AxiosResponse = await axios(config);
  return response;
}

// ###################### End Of EquipmentType Apis ################################





// ###################### Start Of Equipment Apis ################################
// it require customer_id, facility_id, equipment_id 

export function getEquipment(token: any, facility_id: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_EQUIPMENT = `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${facility_id}/equipment`
  return axios.get(GET_EQUIPMENT, {headers: {Authorization: `Bearer ${token}`}})
}
export function getEquipmentCarousel(token: any, facility_id: any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_EQUIPMENT = `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${facility_id}/equipment-carousel`
  return axios.get(GET_EQUIPMENT, {headers: {Authorization: `Bearer ${token}`}})
}
export function postEquipment(token: any, load: any) {
  let facility_id = load.facility_id;
  const myDecodedToken: any = decodeToken(token)
  const ADD_EQUIPMENT =  `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${facility_id}/equipment`
  return axios.post(ADD_EQUIPMENT, load, {
    headers: {Authorization: `Bearer ${token}`},
  })
}

export async function deleteEquipment(token: any, facility_id: any, equipment_id: any, ) {
  const myDecodedToken: any = decodeToken(token)
  const DELETE_EQUIPMENT = `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${facility_id}/equipment/${equipment_id}`
  const config: AxiosRequestConfig = {
    method: 'delete',
    url: DELETE_EQUIPMENT,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {id: facility_id},
  }

  const response: AxiosResponse = await axios(config)
  return response
}
export async function updateEquipment(token: any, facility_id: any, equipment_id: any,  status: boolean) {
  const myDecodedToken: any = decodeToken(token)
  const UPDATE_EQUIPMENT = `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${facility_id}/equipment/${equipment_id}`
  const config: AxiosRequestConfig = {
    method: 'patch',
    url: UPDATE_EQUIPMENT,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      id: `${facility_id}`,
      status: `${!status}`,
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}

export async function editEquipment(token: any, values: any,) {
  const myDecodedToken: any = decodeToken(token)
  const EDIT_EQUIPMENT = `${EQUIPMENT}/${myDecodedToken.cuid}/facility/${values.facility_id}/equipment/${values.id}`
  const config: AxiosRequestConfig = {
    method: 'put',
    url: EDIT_EQUIPMENT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      facility_id: values.facility_id,
      name: values.name,
      device_id: values.device_id,
      equipment_type_id: values.equipment_type_id,
      np_current: values.np_current,
      np_hp: values.np_hp,
      np_power_kw: values.np_power_kw,
      np_rpm: values.np_rpm,
      np_voltage: values.np_voltage,
      np_frequency: values.np_frequency,
      np_sync_rpm: values.np_sync_rpm
    },
  }

  const response: AxiosResponse = await axios(config)
  return response
}

// ###################### End Of Equipment Apis ################################



// #################### Start of Dashboard APis ###############################

export function getFacilityRecentEvents(token: any, facilityId:any) {
  const myDecodedToken: any = decodeToken(token)
  const GET_FACILITY_RECENT_EVENTS = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/ts-recent-events`
  return axios.get(GET_FACILITY_RECENT_EVENTS, {headers: {Authorization: `Bearer ${token}`}})
}
export function getFacilityDashboardGraph(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_FACILITY_DASHBORAD_GRAPH = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/ts-electrical-data-history/`
  return axios.get(GET_FACILITY_DASHBORAD_GRAPH, {headers: {Authorization: `Bearer ${token}`}})

}

export function getFftFilesList(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_FFT_FILES_LIST = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/fft-files`
  return axios.get(GET_FFT_FILES_LIST, {headers: {Authorization: `Bearer ${token}`}})

}

export function getFftGraphData(token:any, facilityId:string,selectedEquipment:string,s3Uri:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_FFT_GRAPH_DATA = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/fft-graph-data`
  return axios.post(GET_FFT_GRAPH_DATA,{s3uri:s3Uri}, {headers: {Authorization: `Bearer ${token}`}})

}

export function getEquipmentDetailData(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_EQUIPMENT_DETAIL_DATA = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}`
  return axios.get(GET_EQUIPMENT_DETAIL_DATA, {headers: {Authorization: `Bearer ${token}`}})

}
export function getEsaFilesList(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_ESA_FILES_LIST = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/esa-files`
  return axios.get(GET_ESA_FILES_LIST, {headers: {Authorization: `Bearer ${token}`}})

}

export function getEsaGraphData(token:any, facilityId:string,selectedEquipment:string,s3Uri:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_ESA_GRAPH_DATA = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/esa-graph-data`
  return axios.post(GET_ESA_GRAPH_DATA,{s3uri:s3Uri}, {headers: {Authorization: `Bearer ${token}`}})

}
export function getEquipmentAnalysis(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_EQUIPMENT_ANALYSIS = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/latest-esa-analysis`
  return axios.get(GET_EQUIPMENT_ANALYSIS, {headers: {Authorization: `Bearer ${token}`}})

}
export function getEquipmentSparklineGraphData(token:any, facilityId:string,selectedEquipment:string){
  const myDecodedToken: any = decodeToken(token)
  const GET_EQUIPMENT_SPARKLINE_GRAPH_DATA = `${FACILITY}/${myDecodedToken.cuid}/facility/${facilityId}/equipment/${selectedEquipment}/sparkline-data`
  return axios.get(GET_EQUIPMENT_SPARKLINE_GRAPH_DATA, {headers: {Authorization: `Bearer ${token}`}})

}
