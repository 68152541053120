import { Field, Form, Formik, FormikValues } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useTheme } from "../../../_metronic/layout/components/ThemeProvider/ThemeProvider";
import CONSTANT from "../../Constant.json";
import {
    editDeviceThreshold,
    edittDeviceCalibration,
    getDeviceCalibration,
    getDeviceThreshold,
} from "../../modules/auth/redux/AuthCRUD";
type Props = {
    show: boolean;
    handleClose: () => void;
    deviceId: any;
};

interface IDevice {
    v1: string;
    i1: string;
    v2: string;
    i2: string;
    v3: string;
    i3: string;
}

const inits: IDevice = {
    v1: "",
    i1: "",
    v2: "",
    i2: "",
    v3: "",
    i3: "",
};

const CalibrationModel: React.FC<Props> = ({ show, handleClose, deviceId }) => {
    let deviceSchema = Yup.object().shape({
        v1: Yup.number().required(CONSTANT.v1_REQUIRED),
        i1: Yup.number().required(CONSTANT.i1_REQUIRED),
        v2: Yup.number().required(CONSTANT.v2_REQUIRED),
        i2: Yup.number().required(CONSTANT.i2_REQUIRED),
        v3: Yup.number().required(CONSTANT.v3_REQUIRED),
        i3: Yup.number().required(CONSTANT.i3_REQUIRED),
    });

    const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);

    const [initValues] = useState(inits);
    const { lightTheme, toggleTheme, themeStyles } = useTheme();
    const [defaultValues, setDefaultValues] = useState<any>({});
    const [editClicked, setEditClicked] = useState(false)
    const formikRef: any = useRef();
    const submitStep = (values: IDevice, actions: FormikValues) => {
        edittDeviceCalibration(isAuthorized, deviceId, values)
            .then((data) => {
                if (data.status === 200) {
                    actions.resetForm();
                    // toast.success(CONSTANT.DEVICE_EDITED_SUCCESSFULLY);
                    handleClose();
                } else {
                    // toast.error(CONSTANT.DEVICE_EDITED_FAILED)
                }
            })
            .catch(() => console.log(CONSTANT.DEVICE_EDITED_FAILED));
        setEditClicked(false)

        // setDefaultValues({})
    };
    useEffect(() => {

        if (deviceId && show)
            getDeviceCalibration(isAuthorized, deviceId).then(({ data }) => {
                setDefaultValues(data.data);

                for (let i = 0; i < Object.keys(data.data).length; i++) {
                    //console.log(Object.keys(editData)[i], Object.values(editData)[i])
                    formikRef.current.setFieldValue(
                        Object.keys(data.data)[i],
                        Object.values(data.data)[i]
                    );
                }
            });
    }, [isAuthorized, deviceId, show]);


    return (
        <Modal
            className="modal fade"
            id="kt_modal_select_location"
            data-backdrop="static"
            tabIndex={-1}
            onHide={() => {
                handleClose();
                setDefaultValues({});
                setEditClicked(false)
            }}
            role="dialog"
            show={show}
            dialogClassName="modal-dialog-centered mw-600px h-auto"
            aria-hidden="true"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>
                <div className="modal-header">
                    <h5 className="modal-title"> {editClicked && "Edit"} Calibration</h5>
                    <div className="d-flex">

                        {
                            !editClicked &&
                            <button

                                className="btn btn-primary model-submit-btn"
                                onClick={() => setEditClicked(true)}
                            >
                                Edit
                            </button>
                        }
                        <i className="bi bi-x model-close-icon"
                            onClick={() => {
                                handleClose();
                                setEditClicked(false)
                            }}
                        ></i>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="stepper-nav ps-lg-10">
                        <Formik
                            validationSchema={deviceSchema}
                            initialValues={initValues}
                            onSubmit={submitStep}
                            innerRef={formikRef}
                        >
                            {({ errors, touched, values, handleChange, setFieldValue }) => (
                                <>
                                    <Form>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        i1
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="i1"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.i1 && touched.i1 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.i1}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        v1
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="v1"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.v1 && touched.v1 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.v1}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        i2
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="i2"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.i2 && touched.i2 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.i2}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        v2
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="v2"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.v2 && touched.v2 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.v2}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        i3
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="i3"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.i3 && touched.i3 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.i3}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-stack mb-2">
                                                    {/* begin::Label */}
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                                                        v3
                                                    </label>
                                                    <div className="required"></div>
                                                </div>
                                            </div>
                                            <div className="pt-3 pb-3">
                                                <Field
                                                    className="form-control form-control-lg form-control-solid"
                                                    name="v3"
                                                    type="text"
                                                    disabled={!editClicked}

                                                />
                                            </div>
                                            {errors.v3 && touched.v3 ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span style={{ color: "red" }} role="alert">
                                                            {errors.v3}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="text-center">
                                            {
                                                editClicked && (


                                                    <div className="modal-footer">

                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary model-submit-btn"
                                                        >
                                                            Save
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                setEditClicked(false)
                                                            }}
                                                            className="btn  model-cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CalibrationModel;
