import React, { useEffect, useState } from "react";
import Plotly from "react-plotly.js";
import Select from "react-select";
import { SelectStyle } from "../../../helpers/components/SelectStyle";
import { Button } from "@material-ui/core";
import {
  getFftFilesList,
  getFftGraphData,
} from "../../../../app/modules/auth/redux/AuthCRUD";
import { CurrentDateToGivenTimeCalculation } from "../../../helpers/components/TimeDifferenceCalculate";
import { useTheme } from "../../../layout/components/ThemeProvider/ThemeProvider";
import { useRefresh } from "../../../layout/components/ThemeProvider/RefreshButton";
import {
  getTimezoneAbbreviation,
  getTimezoneName,
} from "../../../../app/utils/GetTimeZoneName";

const MyChart = ({ className, facilityId, equipmentId, isAuthorized }) => {
  const [fftFileList, setFFtFileList] = useState([]);
  const { lightTheme } = useTheme();
  const { refresh } = useRefresh();
  const [selectedFFT, setSelectedFFT] = useState("");
  const [chartData, setChartData] = useState();
  const [showLoading, setShowLoading] = useState(false);

  function maxFrequency(frequency, chunkSize = 10000) {
    let maxValue = 0;
    for (let i = 0; i < frequency.length; i += chunkSize) {
      const chunk = frequency.slice(
        i,
        Math.min(i + chunkSize, frequency.length)
      );
      const chunkMax = Math.max(...chunk.map(Math.abs));
      maxValue = Math.max(maxValue, chunkMax);
    }
    return maxValue;
  }

  const [graphData, setGraphData] = useState([
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "Voltage (V)",
      // yaxis: "y1",
      hovertemplate: `<b>Freq: </b> %{x:.2f} Hz<br><b>Amplitude: </b> %{y}%<extra></extra>`,
    },
    {
      x: [],
      y: [],
      type: "line", // or 'line' for a line graph
      mode: "lines", // or 'lines' for just lines
      name: "Current (A)",
      // yaxis: "y2",
      hovertemplate: `<b>Freq: </b> %{x:.2f} Hz<br><b>Amplitude: </b> %{y}%<extra></extra>`,
    },
  ]);

  const [layout, setLayout] = useState({
    // title: "Current, Voltage vs Frequency",
    height: 550,
    margin: {
      t: 40, // Top margin (reduce space)
      r: 80, // Right margin (reduce space)
      b: 80, // Bottom margin (optional adjustment)
      l: 80, // Left margin (optional adjustment)
    },
    xaxis: {
      title: "Frequency (Hz)",
      range: [0, 10],
      showline: true,
      showgrid: false,
      showticklabels: true,
      // domain: [0.05],
      showgrid: false,
      color: lightTheme ? "black" : "white",
    },

    yaxis: {
      title: "% amplitude",
      range: [0, 100],
      showgrid: false,
      titlefont: { color: lightTheme ? "black" : "white" },
      tickfont: { color: lightTheme ? "black" : "white" },
      showline: true,
    },

    legend: {
      bgcolor: "transparent",
      font: { color: lightTheme ? "black" : "white", size: 16 },
    },
  });
  useEffect(() => {
    getFftFilesList(isAuthorized, facilityId, equipmentId)
      .then(({ data }) => {
        // return `${formattedTime}  ${getTimezoneName(timezoneOffset)}`;
        // let options = data.data.map((d) => ({

        //   label:
        //     formattedTime +
        //     getTimezoneName(timezoneOffset) +
        //     CurrentDateToGivenTimeCalculation(d.epoch * 1000),
        //   value: d.s3_uri,
        // }));
        let options = data.data.map((d) => {
          const date = new Date(d.epoch * 1000); // Convert seconds to milliseconds
          const localTime = date.toLocaleTimeString([], { hour12: false });
          const year = date.getFullYear().toString().padStart(4, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-indexed
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours(); // Get hours in 12-hour format
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const ampm = date.getHours() >= 12 ? "PM" : "AM"; // Determine AM/PM
          const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          const timezoneOffset = new Date().getTimezoneOffset();
          return {
            label:
              formattedTime +
              " " +
              // getTimezoneName(timezoneOffset) +
              getTimezoneAbbreviation(timezoneOffset) +
              " (" +
              CurrentDateToGivenTimeCalculation(d.epoch * 1000) +
              ")",
            value: d.s3_uri,
          };
        });

        setFFtFileList(options);
      })
      .catch(() => console.log("FFT Files not found"));
  }, [refresh]);
  const fetchGraphData = () => {
    setShowLoading(true);
    getFftGraphData(isAuthorized, facilityId, equipmentId, selectedFFT)
      .then(({ data }) => {
        let allData = [...graphData];
        allData[0].x = data.data.freq_hz;
        allData[1].x = data.data.freq_hz;
        allData[0].y = data.data.v_amp_pct;
        allData[1].y = data.data.i_amp_pct;

        setGraphData(allData);
        // setData(allData);
        setChartData(data.data);
        setShowLoading(false);
      })
      .catch((e) => {
        console.log("FFT Files not found", e);

        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (Array.isArray(chartData?.freq_hz)) {
      let newLayout = { ...layout };
      newLayout.xaxis.range = [0, maxFrequency(chartData?.freq_hz)];
      setLayout(newLayout);
    }
  }, [chartData]);
  useEffect(() => {
    let newLayout = { ...layout };

    let color = lightTheme ? "black" : "white";
    newLayout.xaxis.color = color;
    newLayout.yaxis.color = color;

    newLayout.yaxis.tickfont.color = color;

    console.log(typeof newLayout.yaxis.title);
    if (typeof newLayout.yaxis.title == "object") {
      newLayout.yaxis.title.font.color = color;
    }

    newLayout.legend.font.color = color;
    newLayout.paper_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    newLayout.plot_bgcolor = lightTheme ? "#a3acd9" : "#203287";
    setLayout(newLayout);
  }, [lightTheme]);

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className="flex-grow-1 card-p pb-0">
          <div className=" flex-stack flex-wrap">
            <div className="me-2">
              <a
                href="#"
                className="text-dark text-hover-primary fw-bolder fs-1"
              >
                Frequency Spectrum Analysis
              </a>

              {/* <div className='text-muted fs-7 fw-bold'>Power KW</div> */}
            </div>

            <div className={`fw-bolder fs-3 my-4`}>
              <div className="d-inline-flex align-items-center ">
                <div className="text-muted fw-bold">Select capture</div>
                &nbsp;
                <Select
                  placeholder="FFT files"
                  styles={SelectStyle}
                  className="fs-8 ms-2 react-select-dropdown fft-files"
                  defaultValue={selectedFFT}
                  onChange={(e) => setSelectedFFT(e?.value)}
                  options={fftFileList}
                />
                &nbsp;
                {selectedFFT && (
                  <Button
                    variant="contained"
                    className="plotBtn"
                    onClick={fetchGraphData}
                    disabled={showLoading}
                  >
                    Plot
                    {showLoading && (
                      <div className="spinner-border ms-2" role="status"></div>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body  flex-column p-0" style={{ display: "flex" }}>
          {/* begin::Stats */}
          {/* end::Stats */}

          {/* begin::Chart */}
          {/* {fftFileList.length == 0 && (
            <p className="fft-not-found">No FFT Files Found</p>
          )} */}
          <Plotly
            config={{ displaylogo: false, responsive: true }}
            data={graphData}
            layout={layout}
            style={{ width: "100%", height: 550 }} // Adjust width and height as needed
          />

          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
};

export default MyChart;
