import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from './AuthCRUD'
import {isExpired, decodeToken} from 'react-jwt'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
}

export interface IAuthState {
  user?: any
  accessToken?: string
}
export interface IData {
  type?: any
  payload?: any
}

// Define the type for your action
interface LoginAction {
  type: typeof actionTypes.Login
  payload: {
    accessToken: string // Adjust this type as needed
  }
}

interface UserRequestedAction {
  type: typeof actionTypes.UserRequested
  payload: {
    accessToken: string // Adjust this type as needed
  }
}

const faclity_manager = {
  cusutomer: {add: false, edit: false, list: false, delete: false, view: false},
  facility: {add: true, edit: true, list: true, delete: true, view: true},
  userRole: {add: true, edit: true, list: true, delete: true, view: false},
  user: {add: true, edit: true, list: true, delete: true, view: true},
  device: {add: false, edit: false, list: false, delete: false, view: false},
  EquipmentType: {add: true, edit: true, list: true, delete: true, view: false},
  Equipment: {add: true, edit: true, list: true, delete: true, view: true},
}

const faclity_worker = {
  cusutomer: {add: false, edit: false, list: false, delete: false, view: false},
  facility: {add: true, edit: true, list: true, delete: true, view: true},
  userRole: {add: false, edit: false, list: false, delete: false, view: false},
  user: {add: true, edit: true, list: true, delete: true, view: false},
  device: {add: false, edit: false, list: false, delete: false, view: false},
  EquipmentType: {add: false, edit: false, list: false, delete: false, view: false},
  Equipment: {add: false, edit: false, list: false, delete: false, view: true},
}

const employee = {
  cusutomer: {add: true, edit: true, list: true, delete: true, view: true},
  facility: {add: true, edit: true, list: true, delete: true, view: true},
  userRole: {add: true, edit: true, list: true, delete: true, view: true},
  user: {add: true, edit: true, list: true, delete: true, view: true},
  device: {add: true, edit: true, list: true, delete: true, view: true},
  EquipmentType: {add: true, edit: true, list: true, delete: true, view: true},
  Equipment: {add: true, edit: true, list: true, delete: true, view: true},
} 

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        const role =
          user.data.role.name === 'Electrosine Employee'
            ? employee
            : user.data.role.name === 'Faclity Worker'
            ? faclity_worker
            : faclity_manager

        //console.log(user.data.role.name, 'users----', role)
        localStorage.setItem('user_role', user.data.role.name)
        return {...state, user, role}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (accessToken: string) => ({
    type: actionTypes.UserRequested,
    payload: {accessToken},
  }),
  fulfillUser: (user: any) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: any) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(action: LoginAction) {
    const {accessToken} = action.payload
    yield put(actions.requestUser(accessToken))
  })

  yield takeLatest(actionTypes.Register, function* registerSaga(action: LoginAction) {
    const {accessToken} = action.payload
    yield put(actions.requestUser(accessToken))
  })

  yield takeLatest(
    actionTypes.UserRequested,
    function* userRequestedSaga(action: UserRequestedAction) {
      const {accessToken} = action.payload

      // Replace this line with your actual API call
      const {data: user} = yield getUserByToken(accessToken)

      // Dispatch an action with the retrieved user data
      yield put(actions.fulfillUser(user))
    }
  )
}
