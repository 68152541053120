import React, { FC, useState, useEffect } from "react";
import { MenuInner } from "./MenuInner";
import { getCustomerDetails } from "../../../../../src/app/modules/auth/redux/AuthCRUD";
import { useSelector } from "react-redux";
import { useTheme } from "../ThemeProvider/ThemeProvider";

const Header: FC = () => {
  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const customer_name = localStorage.getItem("customer_name");
  const [customerName, setCustomerName] = useState([customer_name]);
  const { lightTheme, toggleTheme, themeStyles } = useTheme();
  const [headingName, setHeadingName] = useState("");

  useEffect(() => {
    if (!customer_name) {
      getCustomerDetails(isAuthorized).then((data) => {
        if (data.status === 200) {
          setCustomerName(data.data.data.name);
          // document.title = data.data.data.name;
          localStorage.setItem("customer_name", data.data.data.name);
        }
      });
    }
  }, []);
  useEffect(() => {
    // console.log("window", window.location.pathname);
  }, [window.location]);
  const path = window.location.pathname.split("/");
  useEffect(() => {
    if (path.length == 2) {
      if (path[1] == "customer") {
        setHeadingName("| Customers");
      } else if (path[1] == "device") {
        setHeadingName("| Devices");
      } else if (path[1] == "equipment-type") {
        setHeadingName("| Equipment Type");
      } else if (path[1] == "roles") {
        setHeadingName("| User Roles");
      } else {
        setHeadingName(`| ${path[1]}`);
      }
    } else if (path.length == 4) {
      if (path[2] == "facility") {
        setHeadingName("| Facilty Overview");
      } else {
        setHeadingName(`| ${path[2]}`);
      }
    } else if (path.length > 4) {
      if (path[2] == "facility") {
        setHeadingName("| Facilty Overview | Equipment");
      } else {
        setHeadingName(`| ${path[2]}`);
      }
    } else {
      setHeadingName("|");
    }

    // console.log("path", path);
  }, [path]);

  return (
    <div
      className="header-menu align-items-stretch"
      data-kt-drawer="true"
      data-kt-drawer-name="header-menu"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
      data-kt-swapper="true"
      data-kt-swapper-mode="prepend"
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
        id="#kt_header_menu"
        data-kt-menu="true"
      >
        <MenuInner />
      </div>
      <div className="card-title m-0 custom-BreadCrumb">
        <h3
          className="fw-bolder m-0"
          style={{
            padding: "20px 0px 0px 0px",
            fontSize: 30,
            whiteSpace: "nowrap",
            color: lightTheme ? "#2b2b2b" : "#dedfe0",
          }}
        >
          {customerName} {headingName}{" "}
        </h3>
      </div>
    </div>
  );
};

export { Header };
