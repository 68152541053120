import moment from "moment-timezone";

export function getTimezoneName(offsetMinutes) {
  console.log("offsetMinutes", offsetMinutes);
  // Create a mapping of timezone offsets to timezone names
  const timezoneMap = {
    720: "IDLW",
    660: "NST",
    600: "EST",
    540: "HST",
    480: "PST",
    420: "MST",
    360: "CST",
    300: "EST",
    240: "AST",
    210: "HST",
    180: "BST",
    120: "CEST",
    60: "EET",
    0: "GMT",
    "-60": "CET",
    "-120": "EET",
    "-180": "MSK",
    "-240": "GST",
    "-300": "IRST",
    "-330": "IST",
    "-390": "IST", // <- Add IST with offset -330
    "-345": "NPT",
    "-360": "BST",
    "-420": "BST",
    "-480": "CST",
    "-540": "JST",
    "-570": "ACST",
    "-600": "AEST",
    "-630": "LHST",
    "-660": "MHT",
    "-720": "NZST",
    "-765": "CHAST",
    "-780": "LINT",
    "-840": "TOT",
  };

  // Convert offsetMinutes to string for lookup
  const offsetKey = offsetMinutes.toString();

  // Look up the timezone name in the mapping
  const timezoneName = timezoneMap[offsetKey];

  return timezoneName || "";
}
// const moment = require('moment-timezone');

// Get time zone abbreviation from UTC offset
export function getTimezoneAbbreviation(offset) {
  return moment.tz.zone(moment.tz.guess()).abbr(offset);
}
