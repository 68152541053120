/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import NotificationPage from './NotificationPage'
import CONSTANT from '../../Constant.json'

const NotificationWrapper = () => {
    useEffect(() => {
        const customer_name = localStorage.getItem('customer_name')
        // document.title = `${customer_name} | ${CONSTANT.NOTIFICATION}`
    }, []);

    return (
        <>
            <PageTitle>{CONSTANT.NOTIFICATION}</PageTitle>
            <NotificationPage />
        </>
    )
}

export default NotificationWrapper
