import { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import DeviceWrapper from '../pages/Device/DeviceWrapper'
import EquipmentTypeWrapper from '../pages/Equipment-Type/EquipmentTypeWrapper'
import EquipmentWrapper from '../pages/Equipment/EquipmentWrapper'
import FacilityWrapper from '../pages/Facility/FacilityWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import RoleWrapper from '../pages/Roles/RoleWrapper'
import UserProfile from '../pages/Users/UserProfile'
import UserWrapper from '../pages/Users/UserWrapper'
import CustomerWrapper from '../pages/customers/CustomerWrapper'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { EquipmentDashboardWrapper } from '../pages/dashboard/EquipmentDashboard'
import { FacilityDashboardWrapper } from '../pages/dashboard/FacilityDashboard'
import EnergyWrapper from '../pages/energy/EnergyWrapper'
import HealthWrapper from '../pages/health/HealthWrapper'
import NotificationWrapper from '../pages/notifications/NotificationWrapper'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const role = useSelector<any>(({ auth }) => auth.role) as {
    cusutomer: { view: boolean }
    facility: { view: boolean }
    userRole: { view: boolean }
    user: { view: boolean }
    device: { view: boolean }
    EquipmentType: { view: boolean }
    Equipment: { view: boolean }
  }
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/dashboard' component={DashboardWrapper} />
        <Route exact path='/dashboard/facility/:facilityId' component={FacilityDashboardWrapper} />
        <Route exact path='/dashboard/facility/:facilityId/equipment/:equipmentId/' component={EquipmentDashboardWrapper} />


        {role.cusutomer.view && <Route path='/customer' component={CustomerWrapper} />}
        {role.userRole.view && <Route path='/roles' component={RoleWrapper} />}
        {role.facility.view && <Route path='/facility' component={FacilityWrapper} />}
        {role.user.view && <Route path='/users' component={UserWrapper} />}
        {role.device.view && <Route path='/device' component={DeviceWrapper} />}
        {role.EquipmentType.view && (
          <Route path='/equipment-type' component={EquipmentTypeWrapper} />
        )}
        {role.Equipment.view && (
          <Route path='/equipments/:facility_id?' component={EquipmentWrapper} />
        )}
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/profile' component={UserProfile} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        {/* <Route path='/apps/chat' component={ChatPage} /> */}
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/health' component={HealthWrapper} />
        <Route path='/energy' component={EnergyWrapper} />
        <Route path='/notifications' component={NotificationWrapper} />
        <Redirect from='/auth' to='/dashboard' />
        {/* {authData ?
          <Redirect exact from='/' to={`/facilityDashboard/${authData?.[0]?.id}`} />
          :
        } */}
        <Redirect exact from='/' to={`/dashboard/facility/${localStorage.getItem("selectedfacility")}`} />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
