import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { Field, Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  postFacility,
  getFacility,
  editFacility,
  getCustomer,
} from "../../modules/auth/redux/AuthCRUD";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import timezones from "./TimezoneList.json";
import CONSTANT from "../../Constant.json";
import { useTheme } from "../../../_metronic/layout/components/ThemeProvider/ThemeProvider";
import Select from "react-select";
import {
  SelectStyle,
  modalDropDownStyle,
} from "../../../_metronic/helpers/components/SelectStyle";

type Props = {
  show: boolean;
  handleClose: () => void;
  setData: any;
  editData: any;
  setEditData: any;
};

type Customer = {
  id: string;
  name: string;
};
interface IFacility {
  customer_id: string;
  name: string;
  timezone: string;
}

const inits: IFacility = {
  customer_id: "",
  name: "",
  timezone: "",
};
const timezoneList = timezones.TIMEZONE;
const FacilityModel: React.FC<Props> = ({
  show,
  handleClose,
  setData,
  editData,
  setEditData,
}) => {
  let facilitySchema;
  const user_role = localStorage.getItem("user_role");
  facilitySchema = Yup.object().shape({
    name: Yup.string().required(CONSTANT.FACILITY_IS_REQUIRED),
    timezone: Yup.string().required(CONSTANT.TIMEZONE_IS_REQUIRED),
  });
  const { lightTheme, toggleTheme, themeStyles } = useTheme();

  if (user_role === CONSTANT.ELECTROSINE_EMPLOYEE) {
    facilitySchema = facilitySchema.shape({
      customer_id: Yup.string().required(CONSTANT.CUSTOMER_IS_REQUIRED),
    });
  }

  const isAuthorized = useSelector<any>(({ auth }) => auth.accessToken);
  const [options, setOptions] = useState();
  const [customers, setCustomersData] = useState<Customer[]>([]);

  const [initValues, setInitialValues] = useState(inits);
  const [defaultValues, setDefaultValues] = useState<any>({});
  const formikRef: any = useRef();
  const submitStep = (values: IFacility, actions: FormikValues) => {
    if (editData && editData.id) {
      editFacility(isAuthorized, editData.id, values)
        .then((data) => {
          if (data.status === 200) {
            actions.resetForm();
            // toast.success(CONSTANT.FACILITY_EDITED_SUCCESSFULLY);
            handleClose();
            getFacility(isAuthorized)
              .then(({ data }) => {
                setData(data.data);
              })
              .catch(() => console.log(CONSTANT.FACILITY_DATA_NOT_FOUND));
          } else {
            // toast.error(CONSTANT.FACILITY_EDITED_FAILED);
          }
        })
        .catch(() => toast.error(CONSTANT.FACILITY_EDITED_FAILED));
    } else {
      postFacility(isAuthorized, values)
        .then((data) => {
          if (data.status === 200) {
            // toast.success(CONSTANT.FACILITY_ADDED_SUCCESSFULLY);
            actions.resetForm();
            handleClose();
            getFacility(isAuthorized).then(({ data }) => {
              setData(data.data);
            });
          } else {
            // toast.error(CONSTANT.FACILITY_EDITED_FAILED);
          }
        })
        .catch(() => console.log(CONSTANT.FACILITY_EDITED_FAILED));
    }
    setDefaultValues({})

  };
  useEffect(() => {
    getFacility(isAuthorized).then(({ data }) => {
      setOptions(data.data);
    });
  }, []);

  // When user is electrosine employee then only customer list get for drop down
  useEffect(() => {
    if (user_role === CONSTANT.ELECTROSINE_EMPLOYEE) {
      getCustomer(isAuthorized).then((data) => {
        if (data.status === 200) {
          setCustomersData(data.data.data);
        }
      });
    }
  }, []);
  let customerOptions = customers?.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));
  let timeZoneOptions = timezoneList?.map((value) => ({
    value: value,
    label: value,
  }));

  useEffect(() => {
    if (editData) {
      let values = { ...defaultValues };
      for (let i = 0; i < Object.keys(editData).length; i++) {
        // console.log(Object.keys(editData)[i], Object.values(editData)[i]);
        if (Object.keys(editData)[i]?.trim() == "customer_id") {
          values = {
            ...values,
            customer_id: customerOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0],
          };
        }
        if (Object.keys(editData)[i]?.trim() == "timezone") {
          values = {
            ...values, timezone: timeZoneOptions?.filter(
              (d) => d.value == Object.values(editData)[i]
            )[0]
          };
        }

        formikRef.current.setFieldValue(
          Object.keys(editData)[i],
          Object.values(editData)[i]
        );
      }
      setDefaultValues(values);
    }
  }, [editData, customers]);

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      onHide={() => {
        handleClose();
        setDefaultValues({})

      }}
      role="dialog"
      show={show}
      dialogClassName="modal-dialog-centered mw-600px h-auto"
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className={`modal-content ${lightTheme ? "lightCard" : "darkCard"}`}>
        <div className="modal-header">
          <h5 className="modal-title">
            {editData ? CONSTANT.EDIT_FACILITY : CONSTANT.ADD_FACILITY}
          </h5>
          {/* <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div> */}
        </div>
        <div className="modal-body">
          <div className="stepper-nav">
            <Formik
              validationSchema={facilitySchema}
              initialValues={initValues}
              onSubmit={submitStep}
              innerRef={formikRef}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <>
                  <Form>
                    {user_role === CONSTANT.ELECTROSINE_EMPLOYEE ? (
                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-stack">
                            {/* begin::Label */}
                            <label className="form-label fw-bolder text-dark fs-6 mb-0">
                              Customer
                            </label>
                            <div className="required"></div>
                          </div>
                        </div>
                        <div className="pt-2 pb-2">
                          <Field
                            // as='select'
                            name="customer_id"
                          // className='form-select form-select-solid form-control-lg'
                          >
                            {/* <option value=''>Select Customer</option> */}
                            {/* {customers
                              ? customers.map((value, index) => {
                                if (editData && value.id === editData.customer_id)
                                  return (<option key={value.id} value={value.id} selected>{value.name}</option>)
                                else
                                  return (<option key={value.id} value={value.id}>{value.name}</option>)
                              })
                              : ''} */}
                            {({ field, form }: any) => (
                              <Select
                                {...field}
                                placeholder="Select Customer"
                                styles={{
                                  ...SelectStyle,
                                  ...modalDropDownStyle,
                                }}
                                className="fs-8 react-select-dropdown modal-dropdown"
                                value={
                                  defaultValues?.customer_id ?? null
                                }
                                // onChange={e => setSelectedEquipment(e.value)}
                                options={customerOptions}
                                onChange={(option: any) => {
                                  setDefaultValues({
                                    ...defaultValues,
                                    customer_id: option,
                                  });
                                  formikRef.current.setFieldValue(
                                    "customer_id",
                                    option?.value
                                  );
                                }}
                                onBlur={(e) => {
                                  // handleBlur(e);
                                  formikRef.current.setFieldTouched(
                                    "customer_id",
                                    true
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>

                        {errors.customer_id && touched.customer_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span style={{ color: "red" }} role="alert">
                                {errors.customer_id}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-stack">
                          {/* begin::Label */}
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Facility Name
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className="pt-2 pb-2">
                        <Field
                          className="form-control form-control-lg form-control-solid"
                          name="name"
                          type="text"
                        />
                      </div>
                      {errors.name && touched.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span style={{ color: "red" }} role="alert">
                              {errors.name}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-between p-2">
                        <div className="d-flex flex-stack">
                          {/* begin::Label */}
                          <label className="form-label fw-bolder text-dark fs-6 mb-0 ">
                            Timezone
                          </label>
                          <div className="required"></div>
                        </div>
                      </div>
                      <div className="pt-2 pb-2">
                        {/* <Field
                          className='form-control form-control-lg form-control-solid'
                          name='timezone'
                          type='text'
                        /> */}
                        <Field
                          as="select"
                          name="timezone"
                          className="form-select form-select-solid form-control-lg"
                        >
                          {/* <option value="">Select Timezone</option>
                          {timezoneList.map((value: any) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))} */}
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              placeholder="Select Timezone"
                              styles={{
                                ...SelectStyle,
                                ...modalDropDownStyle,
                              }}
                              className="fs-8 react-select-dropdown modal-dropdown"
                              value={
                                editData ? defaultValues?.timezone : null
                              }
                              // onChange={e => setSelectedEquipment(e.value)}
                              options={timeZoneOptions}
                              onChange={(option: any) => {
                                setDefaultValues({
                                  ...defaultValues,
                                  timezone: option,
                                });
                                formikRef.current.setFieldValue(
                                  "timezone",
                                  option?.value
                                );
                              }}
                              onBlur={(e) => {
                                // handleBlur(e);
                                formikRef.current.setFieldTouched(
                                  "timezone",
                                  true
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      {errors.timezone && touched.timezone ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span style={{ color: "red" }} role="alert">
                              {errors.timezone}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="text-center">
                      <div className="modal-footer">
                        {editData ? (
                          <button
                            type="submit"
                            className="btn btn-primary model-submit-btn"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary model-submit-btn"
                          >
                            Submit
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            handleClose();
                            setDefaultValues({})

                          }}
                          className="btn  model-cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FacilityModel;
